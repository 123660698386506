import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { publicInstance } from '../../config/api';
import {
  confirmAssetUpload,
  createAsset,
  deleteAsset,
  editAsset,
  getAssetById,
  getAssets,
  getAssetUploadSignedUrl,
} from '../../services/api';
import { handleError } from '../../utils';

export const useGetAssets = (
  query,
  options = { refetchOnReconnect: 'always', refetchOnMount: 'always' },
) => {
  return useQuery(['assets', query], getAssets, {
    onSuccess: (data) => {},
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error fetching the assets',
      });
    },
    ...options,
  });
};

export const useGetAssetById = (assetId) => {
  return useQuery(['assets', assetId], getAssetById, {
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error fetching the asset',
      });
    },
    onSuccess: async (data, variables) => {
      // console.log('Fetched asset', data);
    },
    enabled: !!assetId,
  });
};

export const useCreateAsset = ({ onUploadProgress } = {}) => {
  const queryClient = useQueryClient();

  const createAssetAndUpload = async ({ userId, payload, file }) => {
    const asset = await createAsset({ userId, payload });

    const { fileName } = payload;
    if (asset.objectUri || !file || !fileName) return asset;

    const assetId = asset._id;
    const uploadSignedUrl = await getAssetUploadSignedUrl({
      userId,
      assetId,
      fileName,
    });
    // NOTE: including an unrelated bearer token gives CORS errors!
    await publicInstance.put(uploadSignedUrl, file, {
      headers: { 'Content-Type': 'application/octet-stream' },
      onUploadProgress,
    });
    // get untransformed objectUri before transform job is completed
    const updatedAsset = await confirmAssetUpload({
      userId,
      assetId,
      payload: { fileName },
    });
    return updatedAsset;
  };

  return useMutation(createAssetAndUpload, {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(['assets']);
      toast.success('Asset created successfully');
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error creating the asset',
      });
    },
  });
};

export const useEditAsset = ({ onUploadProgress } = {}) => {
  const queryClient = useQueryClient();

  const editAssetAndUpload = async ({ userId, assetId, payload, file }) => {
    const editedAsset = await editAsset({ userId, assetId, payload });
    if (!file) return editedAsset;

    const { fileName } = payload;
    const uploadSignedUrl = await getAssetUploadSignedUrl({
      userId,
      assetId,
      fileName,
    });
    // NOTE: including an unrelated bearer token gives CORS errors!
    await publicInstance.put(uploadSignedUrl, file, {
      headers: { 'Content-Type': 'application/octet-stream' },
      onUploadProgress,
    });
    // get untransformed objectUri before transform job is completed
    const updatedAsset = await confirmAssetUpload({
      userId,
      assetId,
      payload: { fileName },
    });
    return updatedAsset;
  };

  return useMutation(editAssetAndUpload, {
    onSuccess: async (data, { payload }) => {
      queryClient.invalidateQueries(['assets']);
      queryClient.invalidateQueries(['layer']);      
      toast.success('Asset edited successfully');
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error editing the asset',
      });
    },
  });
};

// export const useToggleAssetVisiblilty = () => {
//   const queryClient = useQueryClient();
//   return useMutation(editAsset, {
//     onSuccess: async (data, { payload }) => {
//       queryClient.invalidateQueries(['layer']);
//     },
//     onError: (error) => {
//       handleError(error, {
//         fallbackMessage: 'There was an error editing the asset',
//       });
//     },
//   });
// };

export const useDeleteAsset = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteAsset, {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(['layers']);
      queryClient.invalidateQueries(['assets']);
      queryClient.invalidateQueries(['likes']);

      toast.success('Asset deleted successfully');
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error deleting the asset',
      });
    },
  });
};
