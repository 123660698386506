/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Image,
  ListGroup,
  Modal,
  ProgressBar,
  Stack,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import _, { omit } from 'lodash';
import { useCreateAsset, useEditAsset, useDeleteAsset } from '../../hooks/data';
import { InputTags } from 'react-bootstrap-tagsinput';
import { mbToBytes, truncate } from '../../utils';
import CustomButton from '../Buttons/CustomButton';
import { Link } from 'react-router-dom';
import { useGetCurrentUser } from '../../hooks/user';

const initialState = {
  title: '',
  description: '',
  image: '',
  tags: [],
  editionType: 'closed',
  initialQuantity: null,
  isCollectable: false,
  published: false,
};

const AssetModal = ({ data, onHideModal }) => {
  const assetId = data?._id;
  const user = useGetCurrentUser();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [formData, setFormData] = useState(initialState);

  const [hasChangedObject, setHasChangedObject] = useState(false);

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const progress = Math.round((loaded * 100) / total);
    setUploadProgress(progress);
  };

  const {
    mutate: createAsset,
    isLoading: isCreatingAsset,
    isSuccess: createAssetSuccess,
  } = useCreateAsset({ onUploadProgress });

  const {
    mutate: editAsset,
    isLoading: isEditingAsset,
    isSuccess: editAssetSuccess,
  } = useEditAsset({ onUploadProgress });
  const {
    mutate: deleteAsset,
    isLoading: isDeletingAsset,
    isSuccess: deleteAssetSuccess,
  } = useDeleteAsset();

  useEffect(() => {
    if (data) {
      setFormData({
        ...initialState,
        ...omit(data, 'owner'),
        image: data?.imageUri,
        fileName: data?.objectUri?.split(`/assets/${assetId}/`)?.[1] ?? null,
        file: null,
      });
    }

    return () => {
      setFormData(initialState);
    };
  }, [data]);

  const _handleClose = () => {
    setFormData(initialState);
    setUploadProgress(0);
    setHasChangedObject(false);
    onHideModal();
  };

  // DO NOT put these effects into one
  // we previously had ```if (createAssetSuccess || editAssetSuccess || deleteAssetSuccess)```
  // and the modal was closing early if performing at least 2 different mutation types (e.g. first create, then edit)
  // because the dependency array changes if one of the 2 changes, but the 1st mutation still has `isSuccess=true`
  // an altarnative to having 3 separate useEffect hooks is to clear the mutation result: `mutation.reset()`
  useEffect(() => {
    if (createAssetSuccess) _handleClose();
  }, [createAssetSuccess]);
  useEffect(() => {
    if (editAssetSuccess) _handleClose();
  }, [editAssetSuccess]);
  useEffect(() => {
    if (deleteAssetSuccess) _handleClose();
  }, [deleteAssetSuccess]);

  const _handleFieldChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;

    if (value === 'true' || value === 'false') {
      value = JSON.parse(value);
      console.log('value', value);
    }
    setFormData({ ...formData, [key]: value });
  };

  const _handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file.size > mbToBytes(5)) {
      toast.error('Image file size should be less than 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, image: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const _handleObjectChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setFormData({ ...formData, fileName: null, file: null });
      return;
    }

    if (file.size > mbToBytes(5))
      return toast.error('Asset file size should be no more than 50MB');

    // check if file type is allowed
    const allowedFileTypes = ['.usdz', '.reality'];
    if (!allowedFileTypes.some((type) => file.name.endsWith(type)))
      return toast.error('Asset file type must be .usdz');

    setFormData({ ...formData, fileName: file.name, file });

    try {
      const reader = new FileReader();

      reader.onloadend = () => {
        setHasChangedObject(true);
        setFormData({ ...formData, fileName: file.name, file: reader.result });
      };
      reader.onprogress = (e) => {
        console.log('progress', e);
        // TODO: show read progress
      };
      reader.onerror = (e) => {
        console.error('file reader error:', e);
        toast.error('Could not read file');
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('_handleObjectChange', error);
    }
  };

  const _handleSubmit = useCallback(() => {
    if (!formData.title) {
      toast.error('Please fill all required fields');
      return;
    }

    if (!formData.fileName && !formData.file) {
      toast.error('Please upload a file');
      return;
    }

    if (formData?.editionType === 'limited' && !formData?.initialQuantity) {
      toast.error('Please enter quantity available');
      return;
    }

    const payload = { ...formData, published: formData.published };

    console.log('payload', payload.published);

    if (payload.image === payload?.imageUri) delete payload.image;
    if (_.isEmpty(data)) {
      createAsset({
        userId: user._id,
        payload,
        file: formData.file,
      });
    } else {
      editAsset({
        userId: data.owner._id,
        assetId,
        payload,
        file: formData.file,
      });
    }
  }, [formData]);

  const _handleDelete = () => {
    deleteAsset({ userId: data.owner, assetId });
  };

  return (
    <Modal
      show={data}
      onHide={_handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?._id ? 'Edit' : 'New'} Collectable
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                name="title"
                type="text"
                onChange={_handleFieldChange}
                defaultValue={formData?.title}
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                rows={3}
                onChange={_handleFieldChange}
                defaultValue={formData?.description}
              />
            </Form.Group>
            {formData?.image ? (
              <Stack gap={2} className="mb-3">
                <Image width={'150px'} src={formData?.image} alt="image" />
                <Link
                  title="remove image"
                  to=""
                  variant="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormData({ ...formData, image: null });
                  }}
                >
                  Remove Image
                </Link>
              </Stack>
            ) : (
              <Form.Group className="mb-3" controlId="image">
                <Form.Label>Preview image</Form.Label>
                <Form.Control
                  name="image"
                  type="file"
                  accept="image/*"
                  onChange={_handleImageChange}
                />
              </Form.Group>
            )}
          </>

          {formData?.fileName ? (
            <Stack gap={2} className="mb-3">
              <Form.Label className="fw-bold">
                3D Asset - {truncate(formData.fileName, 60)}
              </Form.Label>

              <ListGroup>
                <ListGroup.Item
                  onClick={(e) => {
                    e.preventDefault();
                    setFormData({ ...formData, file: null, fileName: null });
                  }}
                  className="cursor-pointer"
                >
                  Re-upload Asset
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => window.open(data?.objectUri, '_blank')}
                  className="cursor-pointer"
                >
                  Download Original File
                </ListGroup.Item>
              </ListGroup>
            </Stack>
          ) : (
            <Form.Group className="mb-3" controlId="object">
              <Form.Label>3D Asset (.usdz only, max 5MB)</Form.Label>
              <Form.Control
                name="object"
                type="file"
                accept=".usdz"
                onChange={_handleObjectChange}
              />
            </Form.Group>
          )}
        </Form>
        {formData?.fileName &&
          hasChangedObject &&
          (isCreatingAsset || isEditingAsset) && (
            <Stack>
              <>
                <Form.Label>Uploading...</Form.Label>
                <ProgressBar
                  animated
                  variant="info"
                  now={uploadProgress}
                  label={`${uploadProgress}%`}
                />
              </>
            </Stack>
          )}

        <Form.Group className="mb-3" controlId="object">
          <Form.Label>Tags</Form.Label>
          <InputTags
            values={formData.tags}
            onTags={(value) => setFormData({ ...formData, tags: value.values })}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="editionType">
          <Form.Label>
            Collectability (allows other users to 'collect' your items)
          </Form.Label>
          <Form.Control
            as="select"
            name="editionType"
            onChange={(e) =>
              setFormData({ ...formData, editionType: e.target.value })
            }
            value={formData?.editionType}
          >
            <option value="open">Unlimited</option>
            <option value="limited">Limited</option>
            <option value="closed">Off</option>
          </Form.Control>
        </Form.Group>

        {formData?.editionType === 'limited' && (
          <Form.Group className="mb-3" controlId="initialQuantity">
            <Form.Label>Quantity available</Form.Label>
            <Form.Control
              name="initialQuantity"
              type="number"
              onChange={_handleFieldChange}
              defaultValue={formData?.initialQuantity}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="published">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            name="published"
            onChange={_handleFieldChange}
            value={formData?.published}
          >
            <option value="true">Published</option>
            <option value="false">Draft</option>
          </Form.Control>
        </Form.Group>

        <h5>
          Once you have created a collectable, you will need to link it to a
          layer in order for it to be displayed in the app. For help, please
          visit our{' '}
          <a
            target={'_blank'}
            className="social-link"
            href="https://discord.gg/UdHwhHfuTU"
            rel="noreferrer"
          >
            Discord.
          </a>
        </h5>
      </Modal.Body>
      <Modal.Footer>
        {data && (
          <CustomButton
            variant="outline-danger"
            type="submit"
            isLoading={isDeletingAsset}
            onClick={_handleDelete}
            className="me-auto"
          >
            Delete
          </CustomButton>
        )}

        <Button variant="outline-dark" onClick={_handleClose}>
          Cancel
        </Button>
        <CustomButton
          variant="primary"
          type="submit"
          isLoading={isCreatingAsset || isEditingAsset}
          onClick={_handleSubmit}
        >
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};
export default AssetModal;
