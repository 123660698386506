import React, { memo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import { FaLink, FaCommentAlt, FaEye, FaEdit } from 'react-icons/fa';
import LayerModal from '../Modals/CampaignModal';
import CampaignFilter from '../Filters/CampaignFilter';
import TetheringModal from '../Modals/TetheringModal';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';

const CampaignsTable = ({ data, userId }) => {
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTetheringModal, setShowTetheringModal] = useState(false);
  // const [showCampaignPushMessageModal, setShowCampaignPushMessageModal] =
  //   useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const getRowStyle = (row, rowIndex) => {
    let baseStyle = {
      verticalAlign: 'middle',
      cursor: 'pointer',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    }; // Default style, this is equivalent to tableCellCentered

    if (row.published) {
      return { ...baseStyle, '--bs-table-bg': '#fff' };
    } else {
      return { ...baseStyle, '--bs-table-bg': '#fc018429' };
    }
  };

  const resetModals = () => {
    setShowEditModal(false);
    setShowTetheringModal(false);
    // setShowCampaignPushMessageModal(false);
    setSelectedCampaign(null);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(row);
    },
  };

  const handleRowClick = (data) => {
    console.log('data', data);
    setSelectedCampaign(data);
    setShowEditModal(true);
  };

  const handleShowTetheringClick = (data) => {
    setSelectedCampaign(data);
    setShowTetheringModal(true);
  };

  // const handlePushMessageClick = (data) => {
  //   setSelectedCampaign(data);
  //   setShowCampaignPushMessageModal(true);
  // };

  const defaultSorted = [
    {
      dataField: 'updatedAt', // name of the field you want to sort by default
      order: 'desc', // or 'asc' for ascending
    },
  ];

  const columns = [
    {
      dataField: 'avatar',
      text: 'Header',
      formatter: (cellContent, campaign) => (
        <img
          src={campaign.headerImage}
          alt={`Header of ${campaign.title}`}
          width="200"
        />
      ),
    },

    {
      dataField: 'title',
      text: 'Title',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    // {
    //   dataField: 'description',
    //   text: 'Description',
    //   // hidden: true,
    // },
    {
      dataField: 'campaignCode',
      text: 'Code',
      sort: true,
    },
    {
      dataField: 'owner',
      text: 'Creator',
      formatter: (cellContent, campaign) => campaign.owner?.userName || 'N/A',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'pinCount',
      text: 'Collectables',
      sort: true,
    },
    {
      dataField: 'subscriberCount',
      text: '# Subscribers',
      sort: true,
    },
    {
      dataField: 'published',
      text: 'Status',
      sort: true,
      formatter: (cell) => (cell ? 'Active' : 'Inactive'),
    },
    {
      dataField: 'updatedAt',
      text: 'Last updated',
      sort: true,
      formatter: (cell) => moment(cell).format('DD MMM YY @ hh:mm A'), // Adjust the format as needed.
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      formatter: (cellContent, row) => (
        <Stack direction="horizontal" gap={3} className="align-items-center">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCampaign(row);
              setShowEditModal(true);
            }}
            variant="outline-dark"
          >
            Edit
          </Button>

          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleShowTetheringClick(row);
            }}
            variant="outline-dark"
          >
            Link Collectables
          </Button>
          {/* <span
            className="action-icon"
            onClick={(e) => {
              e.stopPropagation();
              handlePushMessageClick(row);
            }}
          >
            <FaCommentAlt size={20} color="#328bf9" />
          </span> */}

          <Button
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/layer/${row.slug}`);
            }}
            variant="outline-dark"
          >
            Preview
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <CampaignFilter count={data.length} onChange={() => {}} />
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={data}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 100 })}
        filter={filterFactory()}
        rowStyle={getRowStyle}
        rowEvents={rowEvents}
        defaultSorted={defaultSorted}
        hover
      />
      {showEditModal && (
        <LayerModal data={selectedCampaign} onHideModal={() => resetModals()} />
      )}
      {/* {showCampaignPushMessageModal && (
        <CampaignPushMessageModal
          layerId={selectedCampaign?._id}
          onHideModal={() => resetModals()}
        />
      )} */}
      {showTetheringModal && (
        <TetheringModal
          layerId={selectedCampaign?._id}
          onHideModal={() => resetModals()}
        />
      )}
    </>
  );
};

export default memo(CampaignsTable);
