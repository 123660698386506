/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Button, Form, Image, Modal, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreatePromo, useEditPromo, useDeletePromo } from '../../hooks/data';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils';
import CustomButton from '../Buttons/CustomButton';
import { useGetCurrentUser } from '../../hooks/user';
import _ from 'lodash';

const initialState = {
  title: '',
  content: '',
  externalUrl: '',
  image: '',
  promoCode: '',
  active: false,
};

const PromoModal = ({ data, onHideModal }) => {
  // const isAdmin = useIsAdmin();
  const user = useGetCurrentUser();

  const {
    mutate: createPromo,
    isSuccess: createPromoSuccess,
    isLoading: isCreatingPromo,
  } = useCreatePromo();
  const {
    mutate: editPromo,
    isSuccess: editPromoSuccess,
    isLoading: isEditingPromo,
  } = useEditPromo();
  const {
    mutate: deletePromo,
    isSuccess: deletePromoSuccess,
    isLoading: isDeletingPromo,
  } = useDeletePromo();

  const [formData, setFormData] = useState({
    ...initialState,
    ...data,
  });

  useEffect(() => {
    if (data) {
      setFormData({
        ...initialState,
        ...data,
        image: data?.imageUri,
      });
      console.log(data);
    }

    return () => {
      setFormData(initialState);
    };
  }, [data]);

  const _handleClose = () => {
    setFormData(initialState);
    onHideModal();
  };

  // DO NOT put these effects into one
  // we previously had ```if (createAssetSuccess || editAssetSuccess || deleteAssetSuccess)```
  // and the modal was closing early if performing at least 2 different mutation types (e.g. first create, then edit)
  // because the dependency array changes if one of the 2 changes, but the 1st mutation still has `isSuccess=true`
  // an altarnative to having 3 separate useEffect hooks is to clear the mutation result: `mutation.reset()`
  useEffect(() => {
    if (createPromoSuccess) _handleClose();
  }, [createPromoSuccess]);
  useEffect(() => {
    if (editPromoSuccess) _handleClose();
  }, [editPromoSuccess]);
  useEffect(() => {
    if (deletePromoSuccess) _handleClose();
  }, [deletePromoSuccess]);

  const _handleFieldChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const _handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file.size > 5000000) {
      toast.error('File size should be less than 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      console.log('image loaded');
      setFormData({ ...formData, image: reader.result });
    };
    reader.onerror = (error) => {
      handleError(error, { fallbackMessage: 'Could not load image' });
    };
    reader.readAsDataURL(file);
  };

  const _handleSubmit = () => {
    // check if any required fields are missing and show an error
    if (!formData.title) {
      toast.error('Please fill all required fields');
      return;
    }

    const payload = {
      title: formData.title,
      content: formData.content,
      image: formData.image,
      active: formData.active, // this line is already correctly setting the 'published' field
      externalUrl: formData.externalUrl,
      promoCode: formData.promoCode,
    };

    if (_.isEmpty(data)) {
      createPromo({ userId: user._id, payload });
    } else {
      editPromo({
        userId: data.owner._id,
        promoId: data._id,
        payload,
      });
    }
  };

  const _handleDelete = () => {
    deletePromo({ userId: data?.owner, promoId: data._id });
  };

  return (
    <Modal
      show={data}
      onHide={_handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?._id ? 'Edit' : 'New'} Promo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {formData?.image ? (
            <Stack gap={2} className="mb-3">
              <Image src={formData?.image} alt="image" />
              <Link
                title="remove image"
                to=""
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({ ...formData, image: null });
                }}
              >
                Remove Image
              </Link>
            </Stack>
          ) : (
            // TODO: do not pass image on every edit
            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Promo image</Form.Label>
              <Form.Control
                name="image"
                type="file"
                onChange={_handleImageChange}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              onChange={_handleFieldChange}
              defaultValue={formData?.title}
            />
          </Form.Group>

          <Form.Group className="my-3" controlId="content">
            <Form.Label>Content</Form.Label>
            <Form.Control
              name="content"
              as="textarea"
              rows={3}
              onChange={_handleFieldChange}
              defaultValue={formData?.content}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="externalUrl">
            <Form.Label>External URL</Form.Label>
            <Form.Control
              name="externalUrl"
              type="text"
              placeholder=""
              onChange={_handleFieldChange}
              defaultValue={formData?.externalUrl}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="promoCode">
            <Form.Label>Promo Code</Form.Label>
            <Form.Control
              name="promoCode"
              type="text"
              placeholder="ie. a discount or redemption code"
              onChange={_handleFieldChange}
              defaultValue={formData?.promoCode}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="active">
            <Form.Label>Active</Form.Label>
            <Form.Control
              as="select"
              name="active"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  active: e.target.value === 'true',
                })
              }
              value={formData.active.toString()}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {data && (
          <CustomButton
            variant="outline-danger"
            type="submit"
            onClick={_handleDelete}
            className="me-auto"
            isLoading={isDeletingPromo}
          >
            Delete
          </CustomButton>
        )}

        <Button variant="outline-dark" onClick={_handleClose}>
          Cancel
        </Button>
        <CustomButton
          variant="primary"
          type="submit"
          onClick={_handleSubmit}
          isLoading={isCreatingPromo || isEditingPromo}
        >
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default PromoModal;
