import { Button, Container, ListGroup, Tab, Tabs } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { instance } from '../config/api';
import { toast } from 'react-toastify';
import UsersTable from '../components/Tables/UsersTable';
import { useGetAssets, useGetLayers, useGetPromos } from '../hooks/data';
import CampaignsTable from '../components/Tables/CampaignsTable';
import AssetsTable from '../components/Tables/AssetsTable';
import PromosTable from '../components/Tables/PromosTable';
import { useEffect, useState } from 'react';

const ADMIN_TABS = {
  users: 'users',
  campaigns: 'campaigns',
  collectables: 'collectables',
  promos: 'promos',
  tools: 'tools',
};

const Admin = () => {
  const { data: campaignData = [], isLoading } = useGetLayers();
  const { data: assetData = [], isLoading: isLoadingAssets } = useGetAssets();
  const { data: promoData = [], isLoading: isLoadingPromos } = useGetPromos();

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabKey, setActiveTabKey] = useState(ADMIN_TABS.users);

  const handleSelectTab = (tabKey) => {
    searchParams.set('activeTab', tabKey);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const tabParam = searchParams.get('activeTab');
    const newActiveTab = ADMIN_TABS[tabParam];

    if (newActiveTab && activeTabKey !== newActiveTab) {
      setActiveTabKey(newActiveTab);
    }
  }, [activeTabKey, searchParams]);

  const generateCampaignCodes = async () => {
    const { data } = await instance.post('/admin/campaigns/generate-codes');
    console.log(data);
    toast.success('Campaign codes generated');
    // return data;
  };

  const generateAssetShortCodes = async () => {
    const { data } = await instance.post('/admin/collectables/generate-codes');
    console.log(data);
    toast.success('Asset Short-codes generated');
    // return data;
  };

  const clearAllClaimsAndCollectables = async () => {
    const { data } = await instance.post('/admin/collectables/reset');
    console.log(data);
    toast.success('All claims and collectables cleared');
    // return data;
  };

  return (
    <>
      <Container fluid>
        <h1 className="my-4">Admin Dashboard</h1>

        <Tabs
          // variant="pills"
          className="mt-4"
          defaultActiveKey="users"
          activeKey={activeTabKey}
          onSelect={handleSelectTab}
        >
          <Tab eventKey="users" title="All Users">
            <UsersTable />
          </Tab>
          <Tab eventKey="campaigns" title="All Campaigns">
            <CampaignsTable data={campaignData} />
          </Tab>
          <Tab eventKey="collectables" title="All Collectables">
            <AssetsTable data={assetData} />
          </Tab>
          <Tab eventKey="promos" title="All Promotions">
            <PromosTable data={promoData} />
          </Tab>
          <Tab eventKey="tools" title="Dev Tools">
            <ListGroup className="my-4">
              <ListGroup.Item
                onClick={() => clearAllClaimsAndCollectables()}
                className="cursor-pointer"
              >
                Clear All Claims and Collectables
              </ListGroup.Item>
            </ListGroup>
            <h4>
              Touching the following on PROD will serioulsy mess things up
            </h4>
            <ListGroup className="my-4">
              <ListGroup.Item
                onClick={() => generateCampaignCodes()}
                className="cursor-pointer"
              >
                Generate Campaign Codes (DANGER!)
              </ListGroup.Item>
              <ListGroup.Item
                color="danger"
                onClick={() => generateAssetShortCodes()}
                className="cursor-pointer"
              >
                Generate Asset Short-codes (DANGER!)
              </ListGroup.Item>
            </ListGroup>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default Admin;
