import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';

import MainNav from './components/MainNav';
import AuthProvider from './context/AuthProvider';
import Router from './router';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-tagsinput/dist/index.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.scss';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
          // scope: 'openid profile email offline_access',
        }}
      >
        <AuthProvider>
          <MainNav />
          <Router />
          <ToastContainer />
        </AuthProvider>
      </Auth0Provider>
    </QueryClientProvider>
  );
}

export default App;
