import { useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { createPortalPost, deletePortalPost, editPortalPost } from '../../services/api';
import { handleError } from '../../utils';

export const useCreatePortalPost = () => {
  const queryClient = useQueryClient();
  return useMutation(createPortalPost, {
    onSuccess: (data, params) => {
      toast.success('Wall Art created successfully');
      queryClient.invalidateQueries(['layer']);
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'There was an error creating the Wall Art' });
    },
  });
};

export const useEditPortalPost = () => {
  const queryClient = useQueryClient();
  return useMutation(editPortalPost, {
    onSuccess: async (data, variables) => {
      toast.success('Wall Art edited successfully');
      queryClient.invalidateQueries(['layer']);
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'There was an error editing the Wall Art' });
    },
  });
};

export const useDeletePortalPost = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePortalPost, {
    onSuccess: async (data, variables) => {
      toast.success('Wall Art deleted successfully');
      queryClient.invalidateQueries(['layer']);
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'There was an error deleting the Wall Art' });
    },
  });
};
