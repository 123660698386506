import React, { memo, useState } from 'react';
import { Container, Image, Row, Col, Stack, Button } from 'react-bootstrap';
import { FaTwitter } from 'react-icons/fa';
import { useIsAdmin, useIsSelf } from '../../hooks/user';
import CustomButton from '../Buttons/CustomButton';
import ProfileModal from '../Modals/ProfileModal';

function ProfileHeader({ profileData }) {
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const { userName, avatar, bio, twitter } = profileData;

  const isSelf = useIsSelf(userName);
  const isAdmin = useIsAdmin();

  return (
    <>
      <Container className="profile-header" fluid>
        <Row>
          <Col xs={12}>
            <Row className="mb-4 align-items-center">
              <Col lg={3} xxl={2}>
                <img
                  className="w-75 rounded-circle"
                  src={avatar || '/images/placeholder-avatar.png'}
                  alt="User Avatar"
                />
              </Col>
              <Col lg={9} xxl={10}>
                <h2>{userName}</h2>
                <p>{bio || 'Consider adding a bio to your profile'}</p>
                {twitter && (
                  <a
                    target={'_blank'}
                    rel="noreferrer"
                    href={`https://twitter.com/${twitter}`}
                  >
                    <FaTwitter size={20} color="#00acee" /> @{twitter}
                  </a>
                )}
                <div className="mt-4">
                  {(isAdmin || isSelf) && (
                    <div className="d-flex mt-4">
                      <Button
                        variant={'dark'}
                        onClick={() => setShowEditProfileModal(true)}
                      >
                        Edit Profile
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {showEditProfileModal && (
        <ProfileModal
          onHideModal={() => setShowEditProfileModal(false)}
          profileData={profileData}
        />
      )}
    </>
  );
}

export default memo(ProfileHeader);
