import { useCallback } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Layer from './pages/Layer';
import Profile from './pages/Profile';
import Landing from './pages/Landing';
import Page404 from './pages/404';
import { useIsAdmin, useIsCreator } from './hooks/user';
import Admin from './pages/Admin';
import Campaign from './pages/Campaign';

function Router() {
  const isAdmin = useIsAdmin();
  const isCreator = useIsCreator();

  const protectedRoute = useCallback(
    (target) => {
      if (!isAdmin && !isCreator) {
        return <Landing />;
      }
      return target;
    },
    [isAdmin, isCreator],
  );

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/layer/:layerSlug" element={<Layer />} />
      <Route path="/account/:userName" element={protectedRoute(<Profile />)} />
      <Route
        path="/account/:userName/campaign/:layerSlug"
        element={protectedRoute(<Campaign />)}
      />
      {/* <Route path="/faqs" element={<Faqs />} /> */}
      <Route path="/admin" element={protectedRoute(<Admin />)} />
      {/* The following deal with appclip invokations that are not met by the Native OS */}
      {/* <Route path="/:campaignCode" element={<Asset />} /> */}

      {/* Updated path */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default Router;
