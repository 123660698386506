import axios from 'axios';
import { useAppStore } from '../store';

export const publicInstance = axios.create({});

export const instance = axios.create({
  // baseURL: 'https://local-api.playlayer.xyz/api/v1',
  baseURL: process.env.REACT_APP_API_URL,
});

// Intercepts the api request and injects the session token to make authenticated calls
instance.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) request.headers.Authorization = `Bearer ${authToken}`;

    return request;
  },
  (error) => {
    return Promise.reject(error.message);
  },
);

// Intercepts the response and can handle different error scenarios or fallbacks
instance.interceptors.response.use(
  (response) => {
    // console.log('API Response', response);
    if (response.status === 401) {
      alert('You are not authorized');
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('authToken');
      window.location = '/';
    }

    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  },
);
