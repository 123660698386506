import { Container } from 'react-bootstrap';

const Page404 = ({ heading = 'Not found', subHeading = 'We could not find this page' }) => {
  return (
    <Container className="mt-4">
      <h2>{heading}</h2>
      <h4>{subHeading}</h4>
    </Container>
  );
};
export default Page404;
