import { useEffect, useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ProfileHeader from '../components/Headers/ProfileHeader';
import AssetsTable from '../components/Tables/AssetsTable';
import CampaignsTable from '../components/Tables/CampaignsTable';
import { useAppStore } from '../store';
import { useGetAssets, useGetLayers } from '../hooks/data';
// import { useGetPromos } from '../hooks/data/promos';
// import PromosTable from '../components/Tables/PromosTable';

const ACCOUNT_TABS = {
  campaigns: 'campaigns',
  collectables: 'collectables',
  // promos: 'promos',
};

const Profile = () => {
  const { user } = useAppStore();
  const { data: collectablesData = [] } = useGetAssets({ owner: user?._id }); // TODO: rename this column to 'owner
  const { data: campaignData = [] } = useGetLayers({ owner: user?._id });
  // const { data: promoData = [] } = useGetPromos({ owner: user?._id });

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabKey, setActiveTabKey] = useState(ACCOUNT_TABS.campaigns);

  const handleSelectTab = (tabKey) => {
    searchParams.set('activeTab', tabKey);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const tabParam = searchParams.get('activeTab');
    const newActiveTab = ACCOUNT_TABS[tabParam];

    if (newActiveTab && activeTabKey !== newActiveTab) {
      setActiveTabKey(newActiveTab);
    }
  }, [activeTabKey, searchParams]);

  return (
    <>
      <div
        style={{
          background: '#000',
        }}
      >
        <ProfileHeader profileData={user} />
      </div>

      <Container fluid>
        <Tabs
          className="mt-4"
          activeKey={activeTabKey}
          onSelect={handleSelectTab}
        >
          <Tab eventKey={ACCOUNT_TABS.campaigns} title="Layers">
            <CampaignsTable data={campaignData} userId={user?._id} />
          </Tab>

          <Tab eventKey={ACCOUNT_TABS.collectables} title="Collectables">
            <AssetsTable data={collectablesData} userId={user?._id} />
          </Tab>

          {/* 
          <Tab eventKey={ACCOUNT_TABS.promos} title="Promos">
            <PromosTable data={promoData} userId={user?._id} />
          </Tab> */}
        </Tabs>
      </Container>
    </>
  );
};
export default Profile;
