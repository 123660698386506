/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Image, Modal, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreatePortalPost, useEditPortalPost, useDeletePortalPost } from '../../hooks/data';
import { capitalize, mbToBytes } from '../../utils';
import CustomButton from '../Buttons/CustomButton';
import { Link } from 'react-router-dom';

const initialState = {
  content: '',
  imageUri: '',
  targetUrl: '',
  image: null,
};

const PortalContentModal = ({ postData, layerData, isVisible, onHideModal, userData }) => {
  const {
    mutate: createPortalPost,
    isLoading: isCreatingPortalPost,
    isSuccess: createPostSuccess,
    reset: resetCreateMutation,
  } = useCreatePortalPost();
  const {
    mutate: editPortalPost,
    isLoading: isEditingPortalPost,
    isSuccess: editPostSuccess,
    reset: resetEditMutation,
  } = useEditPortalPost();
  const {
    mutate: deletePortalPost,
    isLoading: isDeletingPortalPost,
    isSuccess: deletePostSuccess,
    reset: resetDeleteMutation,
  } = useDeletePortalPost();

  const [formData, setFormData] = useState(initialState);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isReadingImage, setIsReadingImage] = useState(false);

  const _handleFieldChange = useCallback(
    (e) => {
      const key = e.target.name;
      let value = e.target.value;

      if (value === 'true' || value === 'false') {
        value = JSON.parse(value);
      }

      setFormData({ ...formData, [key]: value });
    },
    [formData],
  );

  const _handleImageChange = useCallback(
    (e) => {
      const file = e.target.files[0];

      if (file.size > mbToBytes(5)) {
        toast.error('File size should be less than 5MB');
        return;
      }

      const reader = new FileReader();
      reader.onloadstart = () => {
        setIsReadingImage(true);
      };
      reader.onerror = () => {
        setIsReadingImage(false);
      };
      reader.onloadend = () => {
        setIsReadingImage(false);
        setFormData({ ...formData, image: reader.result });
      };
      reader.readAsDataURL(file);
    },
    [formData],
  );

  const _handleSave = useCallback(() => {
    // check if any required fields are missing and show an error
    if (!formData.image && !formData.content) {
      toast.error('Please fill all required fields');
      return;
    }

    if (isReadingImage) {
      toast.error('Please wait while the image is being read');
      return;
    }

    const payload = { ...formData };
    delete payload.imageUri;

    if (isEditMode) {
      editPortalPost({ layerId: layerData._id, userId: userData._id, postId: postData._id, payload });
    } else {
      createPortalPost({ layerId: layerData._id, userId: userData._id, payload });
    }
  }, [createPortalPost, editPortalPost, formData, isEditMode, isReadingImage]);

  const _handleDelete = () => {
    if (!postData?._id) return;
    deletePortalPost({ layerId: layerData._id, userId: userData._id, postId: postData._id });
  };

  const _handleClose = () => {
    setFormData(initialState);
    setIsEditMode(false);
    onHideModal();
  };

  useEffect(() => {
    if (postData && isVisible) {
      setFormData({ ...initialState, ...postData, image: postData?.imageUri });
      setIsEditMode(true);
    }

    return () => {
      setFormData(initialState);
    };
  }, [layerData, isVisible]);

  useEffect(() => {
    _handleClose();
  }, [createPostSuccess, editPostSuccess, deletePostSuccess]);

  return (
    <Modal
      show={isVisible}
      onHide={() => _handleClose()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Wall Art</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p2">
        <p>
          Layer Art are pieces of 2d content that can be placed on a surface in
          AR.
        </p>
        <Form>
          <Form.Group className="mb-3" controlId="content">
            <Form.Label>Title (max 80 chars)</Form.Label>

            <Form.Control
              name="content"
              maxLength={80}
              type="text"
              onChange={_handleFieldChange}
              defaultValue={formData?.content}
            />
          </Form.Group>

          {formData?.image ? (
            <Stack gap={2} className="mb-3">
              <Image width={'150px'} src={formData?.image} alt="image" />
              <Link
                title="remove image"
                to=""
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({ ...formData, image: null });
                }}
              >
                Remove Image
              </Link>
            </Stack>
          ) : (
            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                name="image"
                type="file"
                onChange={_handleImageChange}
              />
            </Form.Group>
          )}

          {formData?.assetType === 'external' && (
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>{capitalize(formData?.assetType)} URI</Form.Label>
              <Form.Control
                name="externalAssetUri"
                type="text"
                onChange={_handleFieldChange}
                value={formData?.externalAssetUri}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Links to</Form.Label>
            <Form.Control
              placeholder="https://"
              name="targetUrl"
              type="text"
              onChange={_handleFieldChange}
              value={formData?.targetUrl}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <>
          {isEditMode ? (
            <CustomButton
              variant="outline-danger"
              isLoading={isDeletingPortalPost}
              onClick={_handleDelete}
              className="me-auto"
            >
              Delete
            </CustomButton>
          ) : (
            onHideModal
          )}

          <Button variant="outline-dark" onClick={onHideModal}>
            Cancel
          </Button>
          <CustomButton
            variant="dark"
            isLoading={isCreatingPortalPost || isEditingPortalPost}
            onClick={_handleSave}
          >
            {isEditMode ? 'Save' : 'Create'}
          </CustomButton>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default PortalContentModal;
