import { Row, Col } from 'react-bootstrap';
import LoadingSpinner from '../LoadingSpinner';
import LayerCard from './LayerCard';

const LayerCardList = ({ layers, isLoading, emptyMessage }) => {
  if (isLoading) return <LoadingSpinner message={'Loading layers...'} />;
  if (!layers?.length) return emptyMessage ? <span>{emptyMessage}</span> : null;
  return (
    <Row>
      {layers.map((layer) => {
        return (
          <Col key={layer?._id} xs={12} sm={12} md={12} lg={6} xxl={4}>
            <div className="m-2">
              <LayerCard layerData={layer} />
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default LayerCardList;
