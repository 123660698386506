import { Card, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { truncate } from '../../utils';

const LayerCard = ({ layerData }) => {
  const {
    slug,
    title,
    headerImage,
    owner,
    subscriberCount,
    pinCount,
    published,
  } = layerData;
  const navigate = useNavigate();

  return (
    <Card
      className="layer-card-wrapper popout"
      onClick={() => navigate(`/layer/${slug}`)}
    >
      {!published && <div className="asset-inactive" />}
      <Card.Img
        variant="top"
        src={
          headerImage
            ? headerImage
            : `https://via.placeholder.com/800x600?text=${title}+Layer`
        }
      />

      <Card.Body className="layer-card-wrapper__body">
        <Stack direction={'horizontal'}>
          <Stack
            gap={0}
            direction={'horizontal'}
            className="layer-card-wrapper__header justify-space-between align-items-center d-flex w-100"
          >
            <Stack className="d-flex align-items-start">
              <h4 className="h6 mb-0">
                {truncate(title, 60)}
                {/* <MdVerifiedUser className="ms-2" size={'1.1rem'} color="#0aa315" /> */}
              </h4>
              {/* <p className="mb-0">
                <Link to={`/account/${owner?.userName}`}>
                  <span>
                    By <b>{owner?.userName}</b>
                  </span>
                </Link>
              </p> */}

              {/* <Stack direction="horizontal" gap={1} className="align-items-center">
                <BsFillBoxFill size={'1.2rem'} className="me-1" />
                <p className="mb-0 me-3">{pinCount}</p>

                <BsFillPersonFill size={'1.4rem'} className="me-1" color={isSubscribed ? 'red' : '#000'} />
                <p className="mb-0">{subscriberCount}</p>
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </Card.Body>
      {/* <Card.Footer className="layer-card-wrapper__footer">
        <Stack direction="horizontal" gap={1} className="align-items-center">
          <p className="mb-0 me-3">
            Assets: {pinCount} | Subscribers: {subscriberCount}
          </p>
        </Stack>
      </Card.Footer> */}
    </Card>
  );
};

export default LayerCard;
