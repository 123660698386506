import React from 'react';
import { Button } from 'react-bootstrap';
import { useAuthManager } from '../../context/authManager';


const SigninButton = ({
  buttonConfig = { variant: 'secondary', size: 'lg' },
}) => {
  const { signIn } = useAuthManager();

  return  (
    <Button {...buttonConfig} onClick={signIn}>
      Sign In
    </Button>
  ) 
};

export default SigninButton;
