import { useMemo } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
// import ExplorerMapboxMain from '../components/ExplorerMapboxMain';
import { useGetLayer } from '../hooks/data';
import { sortedBy } from '../utils';
import { useAppStore } from '../store';
import AssetsTable from '../components/Tables/AssetsTable';
import LayerArtTable from '../components/Tables/LayerArtTable';

const Campaign = () => {
  const { layerSlug } = useParams();
  const { user } = useAppStore();

  const { data: layer, isLoading } = useGetLayer(layerSlug);

  const {
    _id: layerId,
    title,
    pins,
    description,
    headerImage,
    owner,
  } = layer || {};

  const campaignAssets = useMemo(() => {
    return sortedBy(pins ?? [], 'createdAt', 'desc');
  }, [pins]);

  const campaignArt = useMemo(() => {
    return sortedBy(layer?.posts ?? [], 'createdAt', 'desc');
  }, [layer?.posts]);

  return (
    <>
      {!isLoading && (
        <>
          <div
            style={{
              minHeight: 450,
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${headerImage})`,
              borderBottom: `1px solid #a5a5a5`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>

          <Container className="py-4" fluid>
            <h1 className="h5 mb-0 playlayer">{title}</h1>
            <p>{description}</p>
            <Tabs variant="pills" className="mt-4">
              <Tab eventKey={'collectables'} title="Linked Collectables">
                <AssetsTable data={campaignAssets} userId={owner?._id} />
              </Tab>

              <Tab eventKey={'layerArt'} title="Layer Art">
                <LayerArtTable data={campaignArt} userId={user?._id} />
              </Tab>
            </Tabs>
          </Container>
        </>
      )}
      {isLoading && <LoadingSpinner message={'Fetching layer...'} />}
    </>
  );
};

export default Campaign;
