import moment from 'moment';

export const shortenAddress = (address, limit = 6) => {
  if (!address) return '';
  return address.substring(0, limit) + '...' + address.substring(address.length - limit);
};

export const removeEmptyValues = (obj) => {
  Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== undefined && delete obj[k]);
  return obj;
};

export const dedupeArray = (arr) => {
  return [...new Set(arr)];
};

export const dedupeArrayByKey = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const formatDate = (date) => {
  return moment(date).format('MMMM Do YYYY');
};

export const getUniq = (arr, fields) =>
  arr ? arr.filter((v, i, a) => a.findIndex((v2) => fields.every((k) => v2.payload[k] === v.payload[k])) === i) : null;

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + '...' : str;
};

export const generateRandomColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

export const sortedBy = (arr, key) => {
  return arr
    .sort((a, b) => {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    })
    .reverse();
};

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
