import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteUser, editUser, getUser, getUsers } from '../../services/api';
import { useAppStore } from '../../store';
import { handleError } from '../../utils';
import { useGetCurrentUser } from '../user';

export const useGetUsers = (query, options = {}) => {
  return useQuery(['users', query], getUsers, {
    onSuccess: async (data, variables) => {},
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Could not get users' });
    },
    ...options,
  });
};

export const useGetUser = (userIdOrUserName) => {
  return useQuery(['users', userIdOrUserName], getUser, {
    onSuccess: async (data, variables) => {},
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Could not get profile' });
    },
    enabled: !!userIdOrUserName,
  });
};

export const useEditUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();
  const { setUser } = useAppStore();
  const location = useLocation();

  return useMutation(({ userId, user }) => editUser(userId, user), {
    onSuccess: async (data, variables) => {
      const newUserName = variables.user?.userName;

      if (location.pathname !== '/admin') {
        setUser({
          ...currentUser,
          ...data,
        });
        navigate(`/account/${newUserName}`, { shallow: true });
      } else {
        queryClient.invalidateQueries(['users']);
      }

      toast.success('Profile updated');

      // queryClient.invalidateQueries(['users', variables.userId]);
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error updating your profile',
      });
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation(({ userId }) => deleteUser(userId), {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(['user']);
      queryClient.invalidateQueries(['users']);

      toast.success('User deleted successfully');
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error deleting the user',
      });
    },
  });
};
