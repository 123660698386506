import { Card, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetIsLoggedIn, useIsAdminOrOwner } from '../../hooks/user';
import { useAppStore } from '../../store';
import LikeButton from '../Buttons/LikeButton';
import { truncate } from '../../utils';
import { useState } from 'react';

import AssetModal from '../Modals/AssetModal';

const CreationCard = ({ asset, campaignCode }) => {
  const userId = useAppStore((state) => state.user?._id);
  const isLoggedIn = useGetIsLoggedIn();

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const {
    _id: assetId,
    title,
    description,
    imageUri,
    isLiked,
    published,
    owner,
  } = asset;
  const ownerUserName = owner?.userName;
  const isOwner = asset?.owner?._id === userId;
  const canEdit = useIsAdminOrOwner(asset?.owner?._id);

  const resetModals = () => {
    setShowEditModal(false);
    setSelectedAsset(null);
  };

  const handleEditClick = (data) => {
    if (!isLoggedIn || !canEdit) return;
    setSelectedAsset(data);
    setShowEditModal(true);
  };

  return (
    <>
      <Card className="mb-4">
        {!published && <div className="asset-inactive" />}

        <div className="action-stack-float">
          {isLoggedIn && (
            <Stack gap={2} className="action-stack" direction="horizontal">
              <div className="action-stack__icon">
                <LikeButton assetId={assetId} isLiked={isLiked} />
              </div>
            </Stack>
          )}
        </div>

        <Card.Img
          variant="top"
          src={imageUri}
          style={{ opacity: published ? 1 : 0.7, cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            handleEditClick(asset);
          }}
        />

        <Card.Body className="d-flex flex-column justify-content-between">
          <h3 className="h6 mb-2">
            <b>{truncate(title, 30)}</b>
          </h3>

          <p className="mb-2">{truncate(description, 120)}</p>

          <p className="mb-0">
            Published by{' '}
            {isOwner ? (
              <Link to={`/account/${ownerUserName}`}>
                <span>You</span>
              </Link>
            ) : (
              <Link to={`/account/${ownerUserName}`}>
                <span>{ownerUserName}</span>
              </Link>
            )}
          </p>
        </Card.Body>
      </Card>
      {showEditModal && (
        <AssetModal data={selectedAsset} onHideModal={() => resetModals()} />
      )}
    </>
  );
};

export default CreationCard;
