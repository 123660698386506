// FeaturedLayersFilter.js

import React from 'react';
import { Container, Form, Stack } from 'react-bootstrap';
import Select from 'react-select';

const FeaturedLayersFilter = ({ numItems, onSortChange }) => {
  const filters = [
    { value: 'createdAt', label: 'Recently added' },
    { value: 'alphabetical', label: 'A to Z' },
    { value: 'subscriberCount', label: 'Most popular' },
  ];

  const handleFilterChange = (selectedOption) => {
    onSortChange(selectedOption.value);
  };

  return (
    <Container fluid className="filter-sort-bar">
      <Stack direction="horizontal" gap={3} className="align-items-center justify-content-between">
        <h2 className="h5 mb-0">{numItems} Layers</h2>
        <Form className="mr-3">
          <Form.Group controlId="contracts">
            <div style={{ minWidth: '160px' }}>
              <Select
                menuPlacement="auto"
                options={filters}
                defaultValue={filters[0]}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 200 }) }}
                onChange={handleFilterChange}
              />
            </div>
          </Form.Group>
        </Form>
      </Stack>
    </Container>
  );
};

export default FeaturedLayersFilter;
