import React, { memo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import PortalContentModal from '../Modals/PortalContentModal';

const LayerArtTable = ({ data, userId }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedArt, setSelectedArt] = useState(null);

  const getRowStyle = (row, rowIndex) => {
    let baseStyle = {
      verticalAlign: 'middle',
      cursor: 'pointer',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    }; // Default style, this is equivalent to tableCellCentered

    if (row.published) {
      return { ...baseStyle };
    } else {
      return { ...baseStyle };
    }
  };

  const resetModals = () => {
    setShowEditModal(false);
    setSelectedArt(null);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(row);
    },
  };

  const handleRowClick = (data) => {
    setSelectedArt(data);
    setShowEditModal(true);
  };

  const defaultSorted = [
    {
      dataField: 'updatedAt', // name of the field you want to sort by default
      order: 'desc', // or 'asc' for ascending
    },
  ];

  const columns = [
    {
      dataField: 'avatar',
      text: 'Image',
      formatter: (cellContent, asset) => (
        <img src={asset.imageUri} alt={`Avatar of ${asset.title}`} width="80" />
      ),
    },
    {
      dataField: 'content',
      text: 'Content',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'targetUrl',
      text: 'Target URL',
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Last updated',
      sort: true,
      formatter: (cell) => moment(cell).format('DD MMM YYYY hh:mm A'), // Adjust the format as needed.
    },
    // {
    //   dataField: 'actions',
    //   text: 'Actions',
    //   isDummyField: true,
    //   formatter: (cellContent, row) => (
    //     <div className="table-actions">
    //       <span
    //         className="action-icon"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           handleShowCodeClick(row);
    //         }}
    //       >
    //         <FaQrcode size={20} color="#328bf9" />
    //       </span>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      {/* <AssetFilter count={data.length} onChange={() => {}} /> */}
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={data}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 50 })}
        filter={filterFactory()}
        rowStyle={getRowStyle}
        rowEvents={rowEvents}
        defaultSorted={defaultSorted}
        hover
      />
      {showEditModal && (
        <PortalContentModal
          data={selectedArt}
          onHideModal={() => resetModals()}
        />
      )}
    </>
  );
};

export default memo(LayerArtTable);
