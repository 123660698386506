import React, { useEffect, useState } from 'react';
import AuthContext from './authContext';
import { requestUserLogin } from '../services/api';
import { useAppStore } from '../store';
import { useQueryClient } from 'react-query';
import { useAuthManager } from './authManager';
import { Button, Modal } from 'react-bootstrap';

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { user, setUser } = useAppStore();

  const [showEmailReminder, setShowEmailReminder] = useState(false);
  const {
    signIn,
    signOut,
    isAuthenticated,
    auth0User,
    getAccessTokenSilently,
    resendVerificationEmail,
  } = useAuthManager();

  useEffect(() => {
    if (!localStorage.getItem('authToken')) {
      setUser(null);
      queryClient.invalidateQueries();
    }

    if (isAuthenticated && auth0User) {
      if (auth0User?.email_verified === false) {
        setShowEmailReminder(true);
        return;
      }

      const authUser = async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: `${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2`,
          });

          if (!token) {
            throw new Error('No token returned');
          }

          localStorage.setItem('authToken', token);

          const userData = await requestUserLogin(auth0User);
          if (userData) {
            setUser(userData);
            queryClient.invalidateQueries();
          }
        } catch (error) {
          console.error(error);
          signOut();
        }
      };

      authUser();
    }
  }, [isAuthenticated, localStorage.getItem('authToken')]);

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
      <Modal
        show={showEmailReminder}
        onHide={() => {
          signOut();
          setShowEmailReminder(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Email is not verified</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>{auth0User?.email}</b> is not verified.
            <br />
            Please check your inbox and click the verification link.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-auto"
            variant="outline-dark"
            onClick={() => {
              signOut();
              setShowEmailReminder(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              resendVerificationEmail();
            }}
          >
            Resend verification email
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
