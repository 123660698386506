/* eslint-disable react-hooks/exhaustive-deps */
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppStore } from '../store';
import { useIsAdminOrOwner } from '../hooks/user';
import '../styles/geocoder.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// import * as turf from '@turf/turf';
import { useCreateCollectionZone } from '../hooks/data';
import { latLngToCircleWithRadius } from '../utils/geo';
import { v4 as uuidv4 } from 'uuid';
import RangeSlider from 'react-bootstrap-range-slider';
import { toast } from 'react-toastify';
mapboxgl.accessToken = process.env.NX_APP_MAPBOX_TOKEN;

const ExplorerMapBoxMini = ({ layer, onMarkerToTetherClick, currentAsset }) => {
  const mapRef = useRef(null);
  const isAdminOrOwner = useIsAdminOrOwner(layer);

  const { currentLocation, setCurrentLocation } = useAppStore();

  const zoom = 15;
  const defaultZoneRadius = 1000;
  const [mapLoaded, setMapLoaded] = useState(false);
  const [dropzoneRadius, setDropzoneRadius] = useState(defaultZoneRadius);
  const [markerToTether, setMarkerToTether] = useState(null);
  const [map, setMap] = useState(null);

  const geocoderId = useMemo(() => uuidv4(), []);

  const _handleDrawCollectionZones = () => {
    resetAddMarker();

    map.getSource('collectionZones').setData(zonesGeoData);

    // Set the bounds of the map to the collectionZones
    const bounds = new mapboxgl.LngLatBounds();
    zonesGeoData.features.forEach((feature) => {
      if (
        feature.geometry &&
        feature.geometry.coordinates &&
        feature.geometry.coordinates[0]
      ) {
        feature.geometry.coordinates[0].forEach((coordinate) => {
          if (coordinate && coordinate.length === 2) {
            bounds.extend(coordinate);
          }
        });
      }
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds, { padding: 50 });
    }

    console.log('currentAsset', currentAsset);

    currentAsset.collectionZones.forEach((collectionZone) => {
      const el = document.createElement('div');
      el.id = `collectionZone-${collectionZone._id}`;
      el.className = `collectionZone`;
      el.style.backgroundImage = `url(${currentAsset?.thumbnail})`;
      el.style.backgroundSize = 'cover';

      const marker = new mapboxgl.Marker({ draggable: true, color: '#000000' })
        .setLngLat(collectionZone.lngLat)
        .addTo(map);

      let markerOrigin = null;

      marker.on('dragstart', (e) => {
        map.getCanvas().style.cursor = 'grabbing';
        markerOrigin = e.target._lngLat;
      });

      marker.on('dragstart', (e) => {
        map.getCanvas().style.cursor = 'grabbing';
      });

      // on drag end check if the collectionZone is within a zone
      marker.on('dragend', (e) => {
        map.getCanvas().style.cursor = 'crosshair';
        const features = map.queryRenderedFeatures(e.target._pos, {
          layers: ['collectionZones'],
        });

        if (!features.length) {
          console.log('Please place the asset within a zone');
          marker.setLngLat(markerOrigin);
          return;
        }

        const payload = {
          lngLat: e.target._lngLat,
        };

        console.log('payload', payload);

        // editCollectionZone({ layerId: selectedLayer._id, collectionZoneId, payload });
      });
    });
  };

  // const removeCollectionZoneFromMap = (collectionZoneId) => {
  //   const collectionZone = document.getElementById(`collectionZone-${collectionZoneId}`);
  //   collectionZone.remove();
  // };
  // const removeAllCollectionZonesFromMap = () => {
  //   const collectionZones = document.querySelectorAll('.collectionZone');
  //   collectionZones.forEach((collectionZone) => collectionZone.remove());
  // };

  const resetAddMarker = () => {
    if (!markerToTether || !map) return;
    markerToTether.remove();
    setDropzoneRadius(defaultZoneRadius);
    setMarkerToTether(null);
    onMarkerToTetherClick(null);
    map.getCanvas().style.cursor = 'crosshair';
    map.getSource('dropzone').setData({
      type: 'FeatureCollection',
      features: [],
    });
  };

  const {
    mutate: createCollectionZone,
    isSuccess: isCreateCollectionZoneSuccess,
  } = useCreateCollectionZone();
  // const { mutate: editCollectionZone, isSuccess: hasEditedCollectionZone } = useEditCollectionZone();
  // const { mutate: deleteCollectionZone, isLoading: isDeletingCollectionZone } = useDeleteCollectionZone();

  const _handleMapClick = (e) => {
    if (!isAdminOrOwner) return;

    const features = map.queryRenderedFeatures(e.point, {
      layers: ['dropzone'],
    });

    // if the click is outside the dropzone zone feature then remove the marker and feature
    if (!features.length && markerToTether) {
      resetAddMarker();
    } else if (features.length && markerToTether) {
      console.log('dropzoneRadius', dropzoneRadius);
      console.log('currentAsset', currentAsset);
      createCollectionZone({
        layerId: layer._id,
        payload: {
          assetId: currentAsset._id,
          lngLat: e.lngLat,
          radius: dropzoneRadius,
        },
      });
    } else {
      _handleTetherMarker(e);
    }
  };

  function getBoundingBoxForCircle(coordinates) {
    const bounds = new mapboxgl.LngLatBounds();
    coordinates[0].forEach((coord) => bounds.extend(coord));
    return bounds;
  }

  const _handleDropzoneRadiusChange = (e, radius) => {
    console.log('radius', radius);
    // check for overlaps
  };

  const _handleTetherMarker = (e) => {
    // Check if the click is within an existing zone
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['collectionZones'],
    });

    if (features.length) {
      toast.error('You already have a asset in this zone');
      return;
    }

    // zoom out the map
    map.flyTo({
      center: e.lngLat,
    });

    map.getCanvas().style.cursor = 'pointer';

    const el = document.createElement('div');
    el.id = `dropzone-marker`;
    el.className = `dropzone-marker`;
    el.style.backgroundColor = 'white';
    el.style.borderRadius = '50%';

    const innerSpan = document.createElement('span');
    innerSpan.style.display = 'inline-block';
    innerSpan.style.width = '100%';
    innerSpan.style.height = '100%';
    innerSpan.style.backgroundImage = `url(/assets/check.png)`;

    innerSpan.style.backgroundSize = 'cover';
    innerSpan.className = `pulse`;

    el.appendChild(innerSpan);

    const marker = new mapboxgl.Marker(el, { draggable: false })
      .setLngLat(e.lngLat)
      .addTo(map);

    setMarkerToTether(marker);
    onMarkerToTetherClick(true);
  };

  const _handleUpdateDropzone = (e) => {
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          latLngToCircleWithRadius(
            markerToTether._lngLat.lat,
            markerToTether._lngLat.lng,
            dropzoneRadius,
          ),
        ],
      },
    };

    map.getSource('dropzone').setData({
      type: 'FeatureCollection',
      features: [feature],
    });

    const bounds = getBoundingBoxForCircle(feature.geometry.coordinates);

    // Zoom and center the map to fit the bounding box
    map.fitBounds(bounds, {
      padding: { top: 20, bottom: 20, left: 20, right: 20 }, // Adjust padding as needed
      linear: true, // Use linear animation for zooming
    });
  };

  const zonesGeoData = useMemo(() => {
    if (!currentAsset?.collectionZones) return null;

    return {
      type: 'FeatureCollection',
      features: currentAsset.collectionZones.map((zone) => {
        return {
          type: 'Feature',
          properties: {
            id: zone._id,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              latLngToCircleWithRadius(
                zone.lngLat.lat,
                zone.lngLat.lng,
                zone.radius,
              ),
            ],
          },
        };
      }),
    };
  }, [currentAsset?.collectionZones]);

  useEffect(() => {
    if (mapLoaded) return;
    const map = new mapboxgl.Map({
      container: mapRef.current,
      zoom: zoom,
      // style: 'mapbox://styles/mapbox/streets-v11',
      style: 'mapbox://styles/mapbox/light-v11',
      antialias: true,
      projection: 'globe',
      pitch: 0,
    });

    if (currentLocation) {
      map.setCenter(currentLocation);
    } else {
      // TODO: handle user denying location access
      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude, longitude } = pos.coords;
        map.setCenter([longitude, latitude]);
        setCurrentLocation([longitude, latitude]);
      });
    }

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      collectionZone: false,
      placeholder: 'Search for a place',
      flyTo: {
        bearing: 0,
        speed: 5,
        curve: 1,
        easing: function (t) {
          return t;
        },
      },
    });

    const geoLocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: false,
      showUserHeading: false,
      showAccuracyCircle: false,
    });

    // Add the map controls
    map.addControl(geoLocate);
    map.getCanvas().style.cursor = 'crosshair';

    map.on('load', () => {
      document.getElementById(geocoderId).appendChild(geocoder.onAdd(map));

      const addLayerToMap = (layerId, fillColor) => {
        map.addLayer({
          id: layerId,
          type: 'fill',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          },
          layout: {},
          paint: {
            'fill-color': fillColor,
            'fill-opacity': 0.5,
          },
        });
      };

      addLayerToMap('dropzone', '#fc0184');
      addLayerToMap('collectionZones', layer?.color);

      console.log('ADDED');

      setMap(map);
      setMapLoaded(true);
    });
  });

  useEffect(() => {
    if (!mapLoaded || !markerToTether) return;
    // Redraws the dropzone when the radius changes
    _handleUpdateDropzone();
  }, [mapLoaded, dropzoneRadius, markerToTether]);

  useEffect(() => {
    if (mapLoaded && zonesGeoData) {
      map.resize();
      _handleDrawCollectionZones();
    }
  }, [mapLoaded, zonesGeoData, isCreateCollectionZoneSuccess]);

  // IMPORTANT: If your state is not updating when you expect it to,
  // check that you have passed in the correct dependencies to the useEffect hook
  useEffect(() => {
    if (!mapLoaded) return;
    map.on('click', _handleMapClick);
    return () => {
      map.off('click', _handleMapClick);
    };
  }, [mapLoaded, markerToTether, dropzoneRadius]);

  return (
    <>
      <p className="p mb-1">
        <b>Set Dropzones</b>
      </p>
      <p>
        Tap on the map and choose set a radius for your Dropzone. Tap the
        'check' button to confirm and place.
      </p>

      {markerToTether && (
        <RangeSlider
          min={25}
          max={50000}
          step={5}
          value={dropzoneRadius}
          onChange={(e) => setDropzoneRadius(e.target.value)}
          tooltipLabel={(currentValue) => `${currentValue / 1000}km`}
          onAfterChange={_handleDropzoneRadiusChange}
        />
      )}

      <section className="map_box_mini_container">
        <div id={geocoderId} className="geocoder"></div>
        <div ref={mapRef} id="map"></div>
      </section>
    </>
  );
};

export default ExplorerMapBoxMini;
