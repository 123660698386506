/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Button, Form, Image, Modal, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreateLayer, useEditLayer, useDeleteLayer } from '../../hooks/data';
import slugify from 'slugify';
import { Link } from 'react-router-dom';
import { handleError, mbToBytes } from '../../utils';
import CustomButton from '../Buttons/CustomButton';
import { useGetCurrentUser, useIsAdmin } from '../../hooks/user';
import _ from 'lodash';

const initialState = {
  title: '',
  slug: '',
  description: '',
  image: '',
  color: '#5254AA',
  published: false,
  featured: false,
};

const slugifyOptions = {
  replacement: '-',
  lower: true, // convert to lower case, defaults to `false`
  strict: true, // strip special characters except replacement, defaults to `false`
  trim: true, // trim leading and trailing replacement chars, defaults to `true`
};

const CampaignModal = ({ data, onHideModal }) => {
  // const isAdmin = useIsAdmin();
  const user = useGetCurrentUser();
  const isAdmin = useIsAdmin();

  const {
    mutate: createLayer,
    isSuccess: createLayerSuccess,
    isLoading: isCreatingLayer,
  } = useCreateLayer();
  const {
    mutate: editLayer,
    isSuccess: editLayerSuccess,
    isLoading: isEditingLayer,
  } = useEditLayer();
  const {
    mutate: deleteLayer,
    isSuccess: deleteLayerSuccess,
    isLoading: isDeletingLayer,
  } = useDeleteLayer();

  const [formData, setFormData] = useState({
    ...initialState,
    ...data,
  });

  useEffect(() => {
    if (data) {
      setFormData({
        ...initialState,
        ...data,
        image: data?.headerImage,
      });
      console.log(data);
    }

    return () => {
      setFormData(initialState);
    };
  }, [data]);

  const _handleClose = () => {
    setFormData(initialState);
    onHideModal();
  };

  // DO NOT put these effects into one
  // we previously had ```if (createAssetSuccess || editAssetSuccess || deleteAssetSuccess)```
  // and the modal was closing early if performing at least 2 different mutation types (e.g. first create, then edit)
  // because the dependency array changes if one of the 2 changes, but the 1st mutation still has `isSuccess=true`
  // an altarnative to having 3 separate useEffect hooks is to clear the mutation result: `mutation.reset()`
  useEffect(() => {
    if (createLayerSuccess) _handleClose();
  }, [createLayerSuccess]);
  useEffect(() => {
    if (editLayerSuccess) _handleClose();
  }, [editLayerSuccess]);
  useEffect(() => {
    if (deleteLayerSuccess) _handleClose();
  }, [deleteLayerSuccess]);

  const _handleFieldChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const _handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file.size > mbToBytes(5)) {
      toast.error('Image file size should be less than 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      console.log('image loaded');
      setFormData({ ...formData, image: reader.result });
    };
    reader.onerror = (error) => {
      handleError(error, { fallbackMessage: 'Could not load image' });
    };
    reader.readAsDataURL(file);
  };

  const _handleSubmit = () => {
    // check if any required fields are missing and show an error
    if (!formData.title) {
      toast.error('Please fill all required fields');
      return;
    }

    const payload = {
      title: formData.title,
      slug: slugify(formData?.title, slugifyOptions),
      description: formData.description,
      image: formData.image,
      published: formData.published, // this line is already correctly setting the 'published' field
      featured: formData.featured,
      color: formData.color,
    };

    if (_.isEmpty(data)) {
      createLayer({ userId: user._id, payload });
    } else {
      editLayer({
        userId: data.owner._id,
        layerId: data._id,
        payload,
      });
    }
  };

  const _handleDelete = () => {
    deleteLayer({ userId: data?.owner, layerId: data._id });
  };

  return (
    <Modal
      show={data}
      onHide={_handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?._id ? 'Edit' : 'New'} Layer
          {/* {data?.campaignCode} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {formData?.image ? (
            <Stack gap={2} className="mb-3">
              <Image src={formData?.image} alt="image" />
              <Link
                title="remove image"
                to=""
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({ ...formData, image: null });
                }}
              >
                Remove Image
              </Link>
            </Stack>
          ) : (
            // TODO: do not pass image on every edit
            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Header image</Form.Label>
              <Form.Control
                name="image"
                type="file"
                onChange={_handleImageChange}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              onChange={_handleFieldChange}
              defaultValue={formData?.title}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="slug">
            <Form.Label>Slug</Form.Label>
            <Form.Control
              name="slug"
              type="text"
              disabled
              value={slugify(formData?.title, slugifyOptions)}
            />
          </Form.Group>
          <Form.Group className="my-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description"
              as="textarea"
              rows={3}
              onChange={_handleFieldChange}
              defaultValue={formData?.description}
            />
          </Form.Group>

          {/* <Form.Label htmlFor="layer-color">
            Pick a color (no reason, yet)
          </Form.Label>
          <Form.Control
            name="color"
            type="color"
            id="layer-color"
            title="Choose your color"
            defaultValue={formData?.color}
            onChange={_handleFieldChange}
            className="mb-3"
          /> */}

          <Form.Group className="mb-3" controlId="published">
            <Form.Label>Visibility</Form.Label>
            <Form.Control
              as="select"
              name="published"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  published: e.target.value === 'true',
                })
              }
              value={formData.published.toString()}
            >
              <option value="true">Public</option>
              <option value="false">Private</option>
            </Form.Control>
          </Form.Group>
          {isAdmin && (
            <Form.Group className="mb-3" controlId="featured">
              <Form.Label>Featured on homepage</Form.Label>
              <Form.Control
                as="select"
                name="featured"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    featured: e.target.value === 'true',
                  })
                }
                value={formData.featured.toString()}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>
          )}

          {/* {isAdmin && (
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Campaign Code</Form.Label>
              <Form.Control
                name="campaignCode"
                type="text"
                maxLength={2}
                placeholder="2 letter code for the App Clip campaign"
                onChange={_handleFieldChange}
                defaultValue={formData?.campaignCode}
              />
            </Form.Group>
          )} */}

          {/* <Form.Group className="mb-3">
            <Form.Label>Collaborators</Form.Label>

            <Select
              isMulti
              options={userList}
              defaultValue={formData?.users}
              onChange={(e) => setFormData({ ...formData, users: e })}
            />
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {data && (
          <CustomButton
            variant="outline-danger"
            type="submit"
            onClick={_handleDelete}
            className="me-auto"
            isLoading={isDeletingLayer}
          >
            Delete
          </CustomButton>
        )}

        <Button variant="outline-dark" onClick={_handleClose}>
          Cancel
        </Button>
        <CustomButton
          variant="primary"
          type="submit"
          onClick={_handleSubmit}
          isLoading={isCreatingLayer || isEditingLayer}
        >
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignModal;
