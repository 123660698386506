import { toast } from 'react-toastify';

export const getErrorMessage = (error, options = {}) => {
  if (options?.customMessage) return options.customMessage;

  // api error
  if (error.response?.data?.message) return error.response?.data?.message;

  if (error?.additionalInfo?.message) return error?.additionalInfo?.message;

  // signing error
  if (error.message?.includes('user rejected')) return 'You rejected the signature';
  if (error?.message) return error?.message;

  return typeof error === 'string' ? error : options.fallbackMessage ?? 'Something went wrong';
};

const defaultOptions = { toast: true, fallbackMessage: 'Something went wrong' };

export const handleError = (error, injectedOptions = {}) => {
  console.error(error);

  const options = { ...defaultOptions, ...injectedOptions };

  const errorMessage = getErrorMessage(error, options);
  if (options?.toast) {
    toast.error(errorMessage);
  }
};
