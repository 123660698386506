import { Stack } from 'react-bootstrap';
import { SpinnerCircular } from 'spinners-react';

const LoadingSpinner = ({ message, isTakeover }) => {
  return (
    <Stack
      style={isTakeover ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#fff' } : {}}
      className="d-flex align-items-center flex-column justify-content-center"
      direction="vertical"
      gap={2}
    >
      <SpinnerCircular size={100} thickness={100} speed={100} color="#fc0184" secondaryColor="#ddd" />
      <p className="h6">{message}</p>
    </Stack>
  );
};

export default LoadingSpinner;
