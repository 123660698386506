import { instance } from '../../config/api';

export const getLikes = async ({ queryKey }) => {
  const [, { userId }] = queryKey;
  const { data } = await instance.get(`/users/${userId}/likes`);
  return data;
};

export const likeAsset = async (assetId) => {
  const { data } = await instance.post(`/assets/${assetId}/like`);
  return data;
};

export const unlikeAsset = async (assetId) => {
  const { data } = await instance.post(`/assets/${assetId}/unlike`);
  return data;
};
