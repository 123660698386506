import QRCode from 'react-qr-code';

const QR = ({ target }) => {
  return (
    <div
      style={{
        height: 'auto',
        margin: '0 auto',
        width: '100%',
        backgroundColor: 'white',
        padding: 8,
      }}
    >
      <QRCode
        size={256}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={target}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};

export default QR;
