import { instance } from '../../config/api';

export const requestUserLogin = async (user) => {

  if (!user.email_verified) {
      throw new Error('Email not verified');
  } else {

    const { data } = await instance.post(`/auth`, {
      payload: user,
    });
    return data;
  }


};

export const getUsers = async ({ queryKey }) => {
  const [, query] = queryKey;
  const { data } = await instance.get(`/users`, { params: query });
  return data;
};

export const getUser = async ({ queryKey }) => {
  const [, userIdOrUserName] = queryKey;
  const { data } = await instance.get(`/users/${userIdOrUserName}`);
  return data;
};

export const editUser = async (userId, payload) => {
  const { data } = await instance.put(`/users/${userId}`, payload);
  return data;
};


export const deleteUser = async (userId) => {
  console.log('userId', userId);
  const { data } = await instance.delete(`/users/${userId}`);
  return data;
};