import { createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppStore } from '../store';
import { toast } from 'react-toastify';
import { instance } from '../config/api';

export const AuthContext = createContext();

export const useAuthManager = () => {
  const { setUser } = useAppStore();
  const {
    loginWithPopup,
    logout,
    isAuthenticated,
    user: auth0User,
    getAccessTokenSilently,
  } = useAuth0();

  const signIn = async () => {
    loginWithPopup();
  };

  const signOut = () => {
    try {
      console.log('logout');
      localStorage.removeItem('authToken');
      setUser(null);
      logout({
        returnTo: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const { data } = await instance.post(`auth/resend-email`, {
        userId: auth0User.sub,
      });

      if (data?.message) {
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    signIn,
    signOut,
    isAuthenticated,
    getAccessTokenSilently,
    resendVerificationEmail,
    auth0User,
  };
};
