import { Row, Col } from 'react-bootstrap';
import LoadingSpinner from '../LoadingSpinner';
import CreationCard from './CreationCard';

const CreationCardList = ({
  assets,
  isLoading,
  emptyMessage,
  layerId = null,
  campaignCode = null,
}) => {
  if (isLoading) return <LoadingSpinner message={'Loading items...'} />;
  if (!assets?.length) return emptyMessage ? <span>{emptyMessage}</span> : null;

  return (
    <>
      <Row>
        {assets.map((asset) => {
          return (
            <Col
              xs={12}
              md={6}
              lg={4}
              xl={4}
              xxl={3}
              key={asset._id}
              className="d-flex align-items-stretch"
            >
              <CreationCard
                layerId={layerId}
                asset={asset}
                campaignCode={campaignCode}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default CreationCardList;
