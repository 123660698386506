import React, { memo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import { FaEdit, FaQrcode, FaShare } from 'react-icons/fa';
import { MdIosShare } from 'react-icons/md';
import AssetModal from '../Modals/AssetModal';
import AssetFilter from '../Filters/AssetFilter';
import AssetCodeModal from '../Modals/AssetCodeModal';
import { Button, Stack } from 'react-bootstrap';

const AssetsTable = ({ data, userId }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const getRowStyle = (row, rowIndex) => {
    let baseStyle = {
      verticalAlign: 'middle',
      cursor: 'pointer',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    }; // Default style, this is equivalent to tableCellCentered

    if (row.published) {
      return { ...baseStyle, '--bs-table-bg': '#fff' };
    } else {
      return { ...baseStyle, '--bs-table-bg': '#fc018429' };
    }
  };

  const resetModals = () => {
    setShowEditModal(false);
    setShowCodeModal(false);
    setSelectedAsset(null);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(row);
    },
  };

  const handleRowClick = (data) => {
    setSelectedAsset(data);
    setShowEditModal(true);
  };

  const handleShowCodeClick = (data) => {
    setSelectedAsset(data);
    setShowCodeModal(true);
  };

  const defaultSorted = [
    {
      dataField: 'updatedAt', // name of the field you want to sort by default
      order: 'desc', // or 'asc' for ascending
    },
  ];

  const columns = [
    {
      dataField: 'avatar',
      text: 'Image',
      formatter: (cellContent, asset) => (
        <img src={asset.imageUri} alt={`Avatar of ${asset.title}`} width="80" />
      ),
    },
    {
      dataField: 'title',
      text: 'Title',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'editionType',
      text: 'Collectability',
      sort: true,
      formatter: (cell) =>
        cell === 'open' ? 'Unlimited' : cell === 'closed' ? 'Off' : 'Limited',
    },
    {
      dataField: 'initialQuantity',
      text: 'Quantity',
      sort: true,
    },
    {
      dataField: 'collectCount',
      text: '# Collectors',
      sort: true,
    },
    {
      dataField: 'likeCount',
      text: '# Likes',
      sort: true,
    },
    {
      dataField: 'published',
      text: 'Status',
      sort: true,
      formatter: (cell) => (cell ? 'Published' : 'Draft'),
    },
    {
      dataField: 'updatedAt',
      text: 'Last updated',
      sort: true,
      formatter: (cell) => moment(cell).format('DD MMM YYYY hh:mm A'), // Adjust the format as needed.
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      formatter: (cellContent, row) => (
        <Stack direction="horizontal" gap={3} className="align-items-center">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setSelectedAsset(row);
              setShowEditModal(true);
            }}
            variant="outline-dark"
          >
            Edit
          </Button>

          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleShowCodeClick(row);
            }}
            variant="outline-dark"
          >
            Share
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <AssetFilter count={data.length} onChange={() => {}} />
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={data}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 100 })}
        filter={filterFactory()}
        rowStyle={getRowStyle}
        rowEvents={rowEvents}
        defaultSorted={defaultSorted}
        hover
      />
      {showEditModal && (
        <AssetModal data={selectedAsset} onHideModal={() => resetModals()} />
      )}
      {showCodeModal && (
        <AssetCodeModal
          data={selectedAsset}
          onHideModal={() => resetModals()}
        />
      )}
    </>
  );
};

export default memo(AssetsTable);
