// LandingPage.js

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useGetLayers } from '../hooks/data';
import LandingHeader from '../components/Headers/LandingHeader';
import FeaturedLayersFilter from '../components/Filters/FeaturedLayersFilter';
import LayerCardList from '../components/Cards/LayerCardList';

const sortLayers = (layers, sortOption) => {
  switch (sortOption) {
    case 'createdAt':
      return [...layers].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      );
    case 'alphabetical':
      return [...layers].sort((a, b) => a.title.localeCompare(b.title));
    case 'subscriberCount':
      return [...layers].sort((a, b) => b.subscriberCount - a.subscriberCount);
    default:
      return layers;
  }
};

const LandingPage = () => {
  const { data: layers, isLoading: isLayersLoading } = useGetLayers();
  const [sortOption, setSortOption] = useState('createdAt');

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  let sortedLayers = [];
  if (Array.isArray(layers)) {
    sortedLayers = sortLayers(layers, sortOption);
  }

  return (
    <>
      <div>
        <LandingHeader />
      </div>
      <Container>
        <FeaturedLayersFilter
          numItems={layers?.length}
          onSortChange={handleSortChange}
        />
        <LayerCardList layers={sortedLayers} isLoading={isLayersLoading} />
      </Container>
    </>
  );
};

export default LandingPage;
