import { useMemo, useState } from 'react';
import { Container, Image, Stack } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import LayerPageFilters from '../components/Filters/LayerPageFilter';
import CreationCardList from '../components/Cards/CreationCardList';
// import ExplorerMapboxMain from '../components/ExplorerMapboxMain';
import SubscriptionButton from '../components/Buttons/SubscriptionButton';
import { useGetLayer } from '../hooks/data';
import { useGetCurrentUserId, useGetIsLoggedIn } from '../hooks/user';
import { sortedBy } from '../utils';

const Layer = () => {
  const { layerSlug } = useParams();
  const { data: layer, isLoading } = useGetLayer(layerSlug);

  const ownerId = layer?.owner ? layer.owner.toString() : null;
  const currentUserId = useGetCurrentUserId();
  const isOwner = ownerId === currentUserId;

  const {
    _id: layerId,
    title,
    pins,
    description,
    headerImage,
    owner,
    isSubscribed,
    subscriberCount,
    campaignCode,
  } = layer || {};

  console.log('layer', layer);

  const isLoggedIn = useGetIsLoggedIn();

  const memoizedLayerAssets = useMemo(() => {
    return sortedBy(pins ?? [], 'createdAt', 'desc');
  }, [pins]);

  return (
    <>
      {!isLoading && (
        <>
          <div
            className="layer-header"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${headerImage})`,
              borderBottom: `1px solid #a5a5a5`,
            }}
          ></div>

          <Container className="py-4">
            <Stack direction="vertical" gap={3}>
              <Stack
                direction="horizontal"
                className="d-flex align-items-center justify-content-between"
              >
                <Stack
                  direction="horizontal"
                  className="d-flex align-items-center"
                >
                  <h1 className="h3 mb-0">{title}</h1>
                </Stack>
              </Stack>

              <Stack
                direction="horizontal"
                className="d-flex align-items-center"
                gap={1}
              >
                <Image
                  src={owner?.avatar}
                  width={40}
                  height={40}
                  roundedCircle
                  className="avatar me-2"
                />

                <p className="mb-0">
                  <Link to={`/account/${owner?.userName}`}>
                    <span>
                      Pubished by <b>{owner?.userName}</b>
                    </span>
                  </Link>
                </p>
              </Stack>

              <p className="h6">{description ? description : ''}</p>

              <Stack
                direction="horizontal"
                className="d-flex align-items-center justify-content-center justify-content-between"
              >
                {isLoggedIn && (
                  <Stack
                    direction="horizontal"
                    className="d-flex align-items-center"
                    gap={2}
                  >
                    <SubscriptionButton
                      isOwner={isOwner}
                      subscriberCount={subscriberCount}
                      isSubscribed={isSubscribed}
                      layerId={layerId}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Container>

          <hr />
          <Container>
            <LayerPageFilters count={memoizedLayerAssets.length} />

            <CreationCardList
              layerId={layerId}
              campaignCode={campaignCode}
              assets={memoizedLayerAssets}
              isLoading={false}
              emptyMessage="This layer has no 3D content yet."
            />
          </Container>
        </>
      )}
      {isLoading && <LoadingSpinner message={'Fetching layer...'} />}
    </>
  );
};

export default Layer;
