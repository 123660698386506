import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getLikes, likeAsset, unlikeAsset } from '../../services/api';
import { handleError } from '../../utils';

export const useGetLikes = (query, options = {}) => {
  return useQuery(['likes', query], getLikes, {
    onSuccess: (data) => {},
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error retrieving likes' });
    },
    ...options,
  });
};

export const useLikeAsset = (assetId) => {
  const queryClient = useQueryClient();

  return useMutation(() => likeAsset(assetId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['layer']);
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error liking this item',
      });
    },
  });
};

export const useUnlikeAsset = (assetId) => {
  const queryClient = useQueryClient();

  return useMutation(() => unlikeAsset(assetId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['layer']);
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'There was an error removing your like',
      });
    },
  });
};
