import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Container,
  Image,
  ListGroup,
  Nav,
  Navbar,
  Offcanvas,
} from 'react-bootstrap';
import {
  FaBars,
  FaTwitter,
  FaTiktok,
  FaInstagram,
  FaDiscord,
} from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetCurrentUser, useGetIsLoggedIn, useIsAdmin } from '../hooks/user';
import { useAuthManager } from '../context/authManager';

function MainNav() {
  const { signOut, signIn } = useAuthManager();
  const isLoggedIn = useGetIsLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAdmin = useIsAdmin()
  const user = useGetCurrentUser();
  const offCanvasRef = useRef();

  const closeOffCanvas = () => offCanvasRef?.current?.backdrop?.click();

  const handleDisconnect = () => {
    signOut();
    closeOffCanvas();
    navigate('/');
  };

  const navigateAndCloseOffCanvas = (path) => {
    closeOffCanvas();
    navigate(path);
  };

  // close menu after logging in/out
  useEffect(() => {
    setIsMenuOpen(false);
  }, [user]);


  return (
    <Navbar
      bg="dark"
      variant="dark"
      className={
        location.pathname.includes('/layer') ? 'navbar__layer py-0' : 'py-0'
      }
    >
      <Container fluid>
        <LinkContainer to={`/`}>
          <Navbar.Brand href="#">
            <Image
              src={require('../assets/logo-trans.png')}
              alt="Logo"
              height="80"
            />
          </Navbar.Brand>
        </LinkContainer>

        <Nav className="justify-content-end flex-grow-1 pe-3 align-items-center">
          <div className="me-4">
            <a href="https://apps.apple.com/us/app/playlayer/id6449148739">
              <img
                width={110}
                src={require('../assets/app-store.png')}
                alt="Download on App Store"
              />
            </a>
          </div>
          {!!user && (
            <Image
              roundedCircle
              width={35}
              height={35}
              src={user?.avatar}
              onClick={() =>
                navigateAndCloseOffCanvas(`/account/${user.userName}`)
              }
              className="cursor-pointer me-4"
            />
          )}

          <Button
            size="xl"
            variant="dark"
            style={{
              fontSize: '1.4rem',
              background: 'rgba(0,0,0,0.1)',
            }}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FaBars />
          </Button>

          <Offcanvas
            show={isMenuOpen}
            onHide={() => setIsMenuOpen(!isMenuOpen)}
            placement="end"
            ref={offCanvasRef}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column justify-content-between">
              <div>
                {isLoggedIn ? (
                  <ListGroup>
                    <>
                      <ListGroup.Item
                        action
                        onClick={() =>
                          navigateAndCloseOffCanvas(`/account/${user.userName}`)
                        }
                      >
                        My Account
                      </ListGroup.Item>
                    </>

                    {isAdmin && (
                      <ListGroup.Item
                        action
                        onClick={() => navigateAndCloseOffCanvas(`/admin`)}
                      >
                        Admin Dashboard
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                ) : (
                  <ListGroup>
                    <ListGroup.Item action onClick={() => signIn()}>
                      Sign In
                    </ListGroup.Item>
                  </ListGroup>
                )}
              </div>

              <ListGroup>
                <ListGroup.Item
                  action
                  onClick={() => {
                    window.open(
                      'https://apps.apple.com/us/app/playlayer/id6449148739',
                      '_blank',
                    );
                    closeOffCanvas();
                  }}
                >
                  Download the App
                </ListGroup.Item>
                {/* <ListGroup.Item action onClick={() => alert('TODO:  API Docs')}>
                  API Docs
                </ListGroup.Item> */}
                {user && (
                  <ListGroup.Item
                    action
                    onClick={() => handleDisconnect()}
                    className="text-danger"
                  >
                    Sign Out
                  </ListGroup.Item>
                )}

                <div className="social-links">
                  <a
                    target={'_blank'}
                    className="social-link"
                    href="https://twitter.com/playlayer_xyz"
                    rel="noreferrer"
                  >
                    <FaTwitter size={25} color="#00acee" />
                  </a>
                  <a
                    target={'_blank'}
                    className="social-link"
                    href="https://discord.gg/UdHwhHfuTU"
                    rel="noreferrer"
                  >
                    <FaDiscord size={25} color="#7259da" />
                  </a>
                  <a
                    target={'_blank'}
                    className="social-link"
                    href="https://www.instagram.com/playlayer_xyz/"
                    rel="noreferrer"
                  >
                    <FaInstagram size={25} color="red" />
                  </a>

                  <a
                    target={'_blank'}
                    className="social-link"
                    href="https://www.tiktok.com/@playlayer_xyz"
                    rel="noreferrer"
                  >
                    <FaTiktok size={25} color="#000" />
                  </a>
                </div>
              </ListGroup>
            </Offcanvas.Body>
          </Offcanvas>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default MainNav;
