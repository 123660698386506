import { create } from 'zustand';
import { configurePersist } from 'zustand-persist';

const { persist, purge } = configurePersist({
  storage: localStorage,
});

export { purge };

export const useAppStore = create(
  persist(
    {
      key: 'app',
      allowlist: ['currentLocation', 'user'],
    },
    (set) => ({
      isDesktopMode:
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ),
      currentLocation: null,
      user: null,
      setCurrentLocation: (location) => set({ currentLocation: location }),
      setUser: (user) => set({ user }),
    }),
  ),
);

// export const useXrStore = create((set) => ({
//   currentLocation: null,
//   isDesktopMode:
//     !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//       navigator.userAgent,
//     ),
//   isXrAssetSelected: false,
//   isGalleryMode: false,
//   selectedAsset: null,
//   layerToEdit: null,
//   selectedPortalItem: null,
//   setSelectedPortalItem: (item) => set({ selectedPortalItem: item }),
//   setGalleryMode: (status) => set({ isGalleryMode: status }),
//   setLayerToEdit: (layer) => set({ layerToEdit: layer }),
//   setIsXrAssetSelected: (status) => set({ isXrAssetSelected: status }),
// }));

// Modals store

export const useModalStore = create((set) => ({
  selectedUser: null,
  selectedLayer: null,
  selectedAsset: null,
  selectedPost: null,
  selectedPortals: null,
  selectedPortal: null,
  modalToOpen: null,
  setLayerToCreate: () =>
    set({ selectedUser: useAppStore.getState().user, modalToOpen: 'LAYER' }),
  setLayerToEdit: (layer) =>
    set({
      selectedLayer: layer,
      selectedUser: layer?.owner,
      modalToOpen: 'LAYER',
    }),
  setAssetToCreate: () =>
    set({ selectedUser: useAppStore.getState().user, modalToOpen: 'ASSET' }),
  setAssetToEdit: (asset) =>
    set({
      selectedAsset: asset,
      selectedUser: asset?.owner,
      modalToOpen: 'ASSET',
    }),
  setAssetToInspect: (asset) =>
    set({
      selectedAsset: asset,
      selectedUser: asset?.owner,
      modalToOpen: 'INSPECTOR',
    }),
  // setPortalPreview: (portal) => set({ selectedPortal: portal, modalToOpen: 'PORTAL_PREVIEW' }),
  setPortalPostToCreate: (layer) =>
    set({
      selectedLayer: layer,
      selectedUser: useAppStore.getState().user,
      modalToOpen: 'PORTAL_CONTENT',
    }),
  setPortalPostToEdit: (layer, post) =>
    set({
      selectedLayer: layer,
      selectedPost: post,
      selectedUser: useAppStore.getState().user,
      modalToOpen: 'PORTAL_CONTENT',
    }),
  setPortalsToEdit: (portals) =>
    set({ selectedPortals: portals, modalToOpen: 'PORTALS' }),
  resetModals: () =>
    set({
      selectedUser: null,
      selectedLayer: null,
      selectedAsset: null,
      selectedPost: null,
      modalToOpen: null,
    }),
}));
