// Converts numeric degrees to radians
export const toRad = (Value) => {
  return (Value * Math.PI) / 180;
};

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export const calcCrow = (lat1, lon1, lat2, lon2) => {
  let R = 6371; // km
  let dLat = toRad(lat2 - lat1);
  let dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d;
};

export const getPolygonCenter = (zone) => {
  const center = zone.geometry.coordinates[0].reduce(
    (acc, curr) => {
      acc[0] += curr[0];
      acc[1] += curr[1];
      return acc;
    },
    [0, 0],
  );
  center[0] /= zone.geometry.coordinates[0].length;
  center[1] /= zone.geometry.coordinates[0].length;

  return center;
};

// heavily taken from here
// https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js
export const latLngToCircleWithRadius = (lat, lng, radiusMeters, { numPoints = 64 } = {}) => {
  var km = radiusMeters * 0.001;

  var coords = [];
  var distanceX = km / (111.32 * Math.cos((lat * Math.PI) / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var point = 0; point < numPoints; point++) {
    theta = (point / numPoints) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    coords.push([lng + x, lat + y]);
  }
  coords.push(coords[0]);

  return coords;
};
