import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useGetLayers } from '../../hooks/data';
import { instance } from '../../config/api';
import { useAppStore } from '../../store';
import { useIsAdmin } from '../../hooks/user';

// Define a new component that will render the option with an image and the text
const CampaignOption = ({ data, innerProps, isFocused }) => {
  return (
    <div
      {...innerProps}
      style={{
        padding: '10px',
        backgroundColor: isFocused ? '#f0f0f0' : 'white',
        fontWeight: 'bold',
      }}
    >
      <img
        src={data.imageUri}
        alt={data.label}
        style={{
          width: '30px',
          height: '30px',
          marginRight: '10px',
          objectFit: 'cover',
        }}
      />
      {data.label} ({data.collectCount} collectors)
    </div>
  );
};

const CollectorOption = ({ data, innerProps, isFocused }) => {
  return (
    <div
      {...innerProps}
      style={{
        padding: '10px',
        backgroundColor: isFocused ? '#f0f0f0' : 'white',
      }}
    >
      <img
        src={data.imageUri}
        alt={data.label}
        style={{
          width: '30px',
          height: '30px',
          marginRight: '10px',
          objectFit: 'cover',
        }}
      />
      {data.label} ({data.collectCount} collectors)
    </div>
  );
};

const PushMessageModal = ({ promoData, onHideModal }) => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const { user } = useAppStore();
  const isAdmin = useIsAdmin();

  const { data: campaignsData } = useGetLayers(
    isAdmin ? {} : { owner: user?._id },
  );

  const [formData, setFormData] = useState({
    message: promoData.title || '',
  });

  const _handleFieldChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const getCampaignData = async () => {
    const { data } = await instance.get(`/layers/${campaignId}`);
    setSelectedCampaign(data);
  };

  const campaignsOptions = useMemo(
    () =>
      campaignsData?.reverse().map((campaign) => ({
        value: campaign._id,
        label: campaign.title,
        imageUri: campaign.headerImage, // include the image URI in the option
      })),
    [campaignsData],
  );

  const collectorOptions = useMemo(
    () =>
      selectedCampaign?.pins?.reverse().map((asset) => ({
        value: asset._id,
        label: asset.title,
        imageUri: asset.imageUri, // include the image URI in the option
        collectCount: asset.collectCount,
      })),
    [selectedCampaign],
  );

  const deviceOptions = useMemo(
    () =>
      devices?.map((device) => ({
        value: device.deviceId,
        label: device.deviceType,
      })),
    [devices],
  );

  const testPushNotification = async () => {
    const { data } = await instance.post(`/users/${user._id}/apn/test`, {
      deviceToken: selectedDevice,
      campaign: selectedCampaign?.title,
      promoId: promoData?._id,
      message: formData?.message || 'Hello from Playlayer!',
    });
    console.log(data);
    toast.success('Test notification sent!');
  };

  const getDevices = async () => {
    const { data } = await instance.get(`/users/${user._id}/apn/devices`);
    setDevices(data.devices);
    console.log(data);
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    if (!campaignId) return;
    getCampaignData();
  }, [campaignId]);

  return (
    <Modal show={campaignsData} onHide={onHideModal} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>Broadcast Push Notification</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <h5>
            <b>Message</b>
          </h5>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Message (Max 210 Characters)</Form.Label>
            <Form.Control
              name="message"
              as="textarea"
              rows={3}
              onChange={_handleFieldChange}
              defaultValue={formData.message}
              placeholder=""
              maxLength={210}
            />
          </Form.Group>
        </Form>

        <h5>
          <b>Audience</b>
        </h5>
        <Form.Group className="mb-3" controlId="assetId">
          <Form.Label>Choose a target campaign</Form.Label>
          <Select
            placeholder=""
            options={campaignsOptions}
            // value={options?.find((p) => p.value === formData.assetId) ?? null}
            onChange={(e) => setCampaignId(e.value)}
            components={{
              Option: CampaignOption, // pass the Option component to the components prop
            }}
          />
        </Form.Group>
        {selectedCampaign && (
          <Form.Group className="mb-3" controlId="assetId">
            <Form.Label>Target Collector of (Optional)</Form.Label>
            <Select
              isMulti
              placeholder="Choose one or more"
              options={collectorOptions}
              // value={options?.find((p) => p.value === formData.assetId) ?? null}
              onChange={(e) => () => {}}
              components={{
                Option: CollectorOption, // pass the Option component to the components prop
              }}
            />
          </Form.Group>
        )}
        {/* 
        <Form.Group className="mb-3" controlId="dateTime">
          <Form.Label>Scheduling</Form.Label>
          <Form.Control
            name="dateTime"
            onChange={_handleFieldChange}
            defaultValue={formData.dateTime}
            placeholder=""
          />
        </Form.Group> */}
        <hr />
        <h5>
          <b>Testing</b>
        </h5>
        <Form.Group className="mb-3" controlId="assetId">
          <Form.Label>
            Ensure you have logged into at least one physical device and allowed
            push notifications, then choose below and click 'Send Test'.
          </Form.Label>
          <Select
            placeholder="Select a device"
            options={deviceOptions}
            // value={options?.find((p) => p.value === formData.assetId) ?? null}
            onChange={(e) => setSelectedDevice(e.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-dark"
          onClick={onHideModal}
          className="me-auto"
        >
          Close
        </Button>
        <Button
          variant="secondary"
          onClick={() => testPushNotification()}
          className="cursor-pointer"
        >
          Send Test
        </Button>
        <Button variant="primary" onClick={onHideModal}>
          Broadcast to Audience
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PushMessageModal;
