import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { instance } from '../../config/api';
import { handleError } from '../../utils';

// Hooks

export const useGetPromos = (query, options = {}) => {
  const fetchPromos = async ({ queryKey }) => {
    const [, queryParams] = queryKey;
    const { data } = await instance.get('/promos', { params: queryParams });
    return data;
  };

  const queryKey = query?.promoSlug ? `'${query.promoSlug}'` : 'promos';
  return useQuery([queryKey, query], fetchPromos, {
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error fetching promos' });
    },
    ...options,
  });
};

export const useGetPromo = (promoId) => {
  const fetchpromo = async ({ queryKey }) => {
    const [, promoId] = queryKey;
    const { data } = await instance.get(`/promos/${promoId}`);
    return data;
  };

  return useQuery(['promo', promoId], fetchpromo, {
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error fetching the promo' });
    },
  });
};

export const useCreatePromo = () => {
  const queryClient = useQueryClient();

  const create = async ({ userId, payload }) => {
    const { data } = await instance.post(`/users/${userId}/promos`, payload);
    return data;
  };

  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries('promos');
      toast.success('Promo created successfully');
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error creating the promo' });
    },
  });
};

export const useEditPromo = (options = {}) => {
  const queryClient = useQueryClient();

  const edit = async ({ userId, promoId, payload }) => {
    const { data } = await instance.put(
      `/users/${userId}/promos/${promoId}`,
      payload,
    );
    return data;
  };

  return useMutation(edit, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries('promos');
      toast.success('Promo edited successfully');
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error editing the promo' });
    },
  });
};

export const useDeletePromo = () => {
  const queryClient = useQueryClient();

  const deletePromoFn = async ({ userId, promoId }) => {
    const { data } = await instance.delete(
      `/users/${userId}/promos/${promoId}`,
    );
    return data;
  };

  return useMutation(deletePromoFn, {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries('promos');
      toast.success('Promo deleted successfully');
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error deleting the promo' });
    },
  });
};
