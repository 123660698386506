import { useEffect, useState } from 'react';
import { useSubscribeToLayer, useUnsubscribeFromLayer } from '../../hooks/data';
import CustomButton from './CustomButton';
import { Stack } from 'react-bootstrap';
import { useGetIsLoggedIn } from '../../hooks/user';

const SubscriptionButton = ({
  isOwner,
  subscriberCount,
  isSubscribed,
  layerId,
}) => {
  const {
    mutate: subscribeToLayer,
    isLoading: isSubscribing,
    isError: hasErroredSubscribing,
  } = useSubscribeToLayer();
  const isLoggedIn = useGetIsLoggedIn();
  const {
    mutate: unsubscribeFromLayer,
    isLoading: isUnsubscribing,
    isError: hasErroredUnsubscribing,
  } = useUnsubscribeFromLayer();

  // optimistic update before the mutation is resolved & cache updated or re-fetching completed
  const [isShowingIsSubscribed, setIsShowingIsSubscribed] =
    useState(isSubscribed);

  // current count for optimistic update of the subscriber count
  const [currentCount, setCurrentCount] = useState(subscriberCount);

  // revert optimistic update if errored
  useEffect(() => {
    if (hasErroredSubscribing || hasErroredUnsubscribing) {
      setIsShowingIsSubscribed(isSubscribed);
      setCurrentCount(subscriberCount);
    }
  }, [
    hasErroredSubscribing,
    hasErroredUnsubscribing,
    isSubscribed,
    subscriberCount,
  ]);

  const _handleToggleSubscription = () => {
    if (isSubscribing || isUnsubscribing) {
      return;
    }

    // update the count optimistically
    const newCount = isShowingIsSubscribed
      ? currentCount - 1
      : currentCount + 1;
    setCurrentCount(newCount);

    setIsShowingIsSubscribed(!isShowingIsSubscribed);

    if (isSubscribed) {
      unsubscribeFromLayer(layerId);
    } else {
      subscribeToLayer(layerId);
    }
  };

  const button = isLoggedIn ? (
    <CustomButton
      // disabled={isOwner}
      size="md"
      variant={isShowingIsSubscribed ? 'danger' : 'success'}
      onClick={_handleToggleSubscription}
    >
      {isShowingIsSubscribed ? 'Unsubscribe' : 'Subscribe'}
    </CustomButton>
  ) : null;

  const subscriberCountText = (
    <Stack direction="horizontal" gap={1} className="align-items-center ms-3">
      <p className="mb-0">{currentCount} Subscribers</p>
    </Stack>
  );

  const content = [button, subscriberCountText];

  return (
    <Stack direction="horizontal" className="align-items-center">
      {content.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </Stack>
  );
};

export default SubscriptionButton;
