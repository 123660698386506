import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { instance } from '../../config/api';
import { handleError } from '../../utils';

// Hooks

export const useGetLayers = (query, options = {}) => {
  const fetchLayers = async ({ queryKey }) => {
    const [, queryParams] = queryKey;
    const { data } = await instance.get('/layers', { params: queryParams });
    return data;
  };

  const queryKey = query?.layerSlug ? `'${query.layerSlug}'` : 'layers';
  return useQuery([queryKey, query], fetchLayers, {
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error fetching layers' });
    },
    ...options,
  });
};

export const useGetSubscribedLayers = (userId, options = {}) => {
  const fetchSubscribedLayers = async ({ queryKey }) => {
    const [, userId] = queryKey;
    const { data } = await instance.get(
      `/users/${userId}/layers/subscriptions`,
    );
    return data;
  };

  return useQuery(['subscribedLayers', userId], fetchSubscribedLayers, {
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'Error fetching subscribed layers',
      });
    },
    ...options,
  });
};

export const useGetLayer = (layerIdOrSlug) => {
  const fetchLayer = async ({ queryKey }) => {
    const [, layerIdOrSlug] = queryKey;
    const { data } = await instance.get(`/layers/${layerIdOrSlug}`);
    return data;
  };

  return useQuery(['layer', layerIdOrSlug], fetchLayer, {
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error fetching the layer' });
    },
  });
};

export const useCreateLayer = () => {
  const queryClient = useQueryClient();

  const create = async ({ userId, payload }) => {
    const { data } = await instance.post(`/users/${userId}/layers`, payload);
    return data;
  };

  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries('layers');
      toast.success('Layer created successfully');
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error creating the layer' });
    },
  });
};

export const useEditLayer = (options = {}) => {
  const queryClient = useQueryClient();

  const edit = async ({ userId, layerId, payload }) => {
    console.log('userId', userId);
    const { data } = await instance.put(
      `/users/${userId}/layers/${layerId}`,
      payload,
    );
    return data;
  };

  return useMutation(edit, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries('layers');
      toast.success('Layer edited successfully');
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error editing the layer' });
    },
  });
};

export const useDeleteLayer = () => {
  const queryClient = useQueryClient();

  const deleteLayerFn = async ({ userId, layerId }) => {
    const { data } = await instance.delete(
      `/users/${userId}/layers/${layerId}`,
    );
    return data;
  };

  return useMutation(deleteLayerFn, {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries('layers');
      toast.success('Layer deleted successfully');
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error deleting the layer' });
    },
  });
};

export const useAddAssetToLayer = () => {
  const queryClient = useQueryClient();

  const addAsset = async ({ layerId, assetId }) => {
    const { data } = await instance.post(
      `/layers/${layerId}/assets/${assetId}/add`,
    );
    return data;
  };

  return useMutation(addAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries('layers');
      queryClient.invalidateQueries('layer');
      toast.success('Collectable has been linked to the layer');
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'Error tethering asset to layer' });
    },
  });
};

export const useRemoveAssetFromLayer = () => {
  const queryClient = useQueryClient();

  const removeAsset = async ({ layerId, assetId }) => {
    const { data } = await instance.post(
      `/layers/${layerId}/assets/${assetId}/remove`,
    );
    return data;
  };

  return useMutation(removeAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries('layers');
      queryClient.invalidateQueries('layer');
      toast.success('Collectable has been unlinked from the layer');
    },
    onError: (error) => {
      handleError(error, {
        fallbackMessage: 'Error untethering asset from layer',
      });
    },
  });
};
