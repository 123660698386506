import { useCallback, useEffect, useState } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { useLikeAsset, useUnlikeAsset } from '../../hooks/data';

const LikeButton = ({ assetId, isLiked }) => {
  const {
    mutate: like,
    isLoading: isLiking,
    isError: hasErroredLiking,
  } = useLikeAsset(assetId);
  const {
    mutate: unlike,
    isLoading: isUnliking,
    isError: hasErroredUnliking,
  } = useUnlikeAsset(assetId);

  // optimistic update before the mutation is resolved & cache updated or re-fetching completed
  const [isShowingisLiked, setIsShowingisLiked] = useState(isLiked);

  useEffect(() => {
    if (hasErroredLiking || hasErroredUnliking) {
      setIsShowingisLiked(isLiked);
    }
  }, [hasErroredLiking, hasErroredUnliking, isLiked]);

  const _handleToggleLike = useCallback(
    (e) => {
      if (isLiking || isUnliking) return;
      setIsShowingisLiked(!isShowingisLiked);
      isLiked ? unlike() : like();
    },
    [isLiking, isLiked, isShowingisLiked, isUnliking, like, unlike],
  );
  return (
    <div onClick={_handleToggleLike} className="ms-auto cursor-pointer">
      <AiFillHeart size={25} color={isShowingisLiked ? 'red' : '#dad7d7'} />
    </div>
  );
};

export default LikeButton;
