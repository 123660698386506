import { Button, Spinner, Stack } from 'react-bootstrap';

const CustomButton = ({ isLoading, children, disabled, variant, className, onClick, style, icon, text, size }) => (
  <Button
    disabled={disabled || isLoading}
    variant={variant}
    size={size}
    className={className}
    onClick={onClick}
    style={style}
  >
    <Stack direction="horizontal" gap={1}>
      {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
      {!isLoading && icon}
      <span>{children ?? text}</span>
    </Stack>
  </Button>
);

export default CustomButton;
