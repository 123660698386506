import { Container, Stack } from 'react-bootstrap';

const LayerPageFilters = ({ count }) => {
  return (
    <Container fluid className="filter-sort-bar lifted">
      <Stack
        direction="horizontal"
        gap={3}
        className="align-items-center justify-content-between"
      >
        <h2 className="h6 mb-0">{`${count || 'No'} Items`}</h2>
      </Stack>
    </Container>
  );
};

export default LayerPageFilters;
