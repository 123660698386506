import { instance } from '../../config/api';

export const getPortalPosts = async ({ queryKey }) => {
  const { data } = await instance.get('/portalPosts');
  return data;
};

// export const getPortalPostById = async ({ queryKey }) => {
//   const [, portalPostId] = queryKey;
//   console.log('portalPostId', portalPostId);
//   const { data } = await instance.get(`/portalPosts/${portalPostId}`);
//   return data;
// };

export const createPortalPost = async ({ userId, layerId, payload }) => {
  const { data } = await instance.post(
    `/users/${userId}/layer/${layerId}/post`,
    payload,
  );
  return data;
};

export const editPortalPost = async ({ userId, layerId, postId, payload }) => {
  const { data } = await instance.put(
    `/users/${userId}/layer/${layerId}/post/${postId}`,
    payload,
  );
  return data;
};

export const deletePortalPost = async ({ userId, layerId, postId }) => {
  const { data } = await instance.delete(
    `/users/${userId}/layer/${layerId}/post/${postId}`,
  );
  return data;
};
