import React from 'react';
import { Container } from 'react-bootstrap';
import { useGetIsLoggedIn } from '../../hooks/user';
import SigninButton from '../Auth/SigninButton';

const LandingHeader = () => {
  const isLoggedIn = useGetIsLoggedIn();
  return (
    <div className="home-wrapper">
      <div className="background-image">
        <Container fluid className="content">
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <h1 className="title playlayer">PlayLayer</h1>
            <p className="subtitle">Reality Reimagined</p>
            {!isLoggedIn && <SigninButton />}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LandingHeader;
