import { instance } from '../../config/api';

export const getSubscriptions = async ({ queryKey }) => {
  const [, query] = queryKey;
  const { data } = await instance.get('/subscriptions', { params: query });
  return data;
};

export const subscribeToLayer = async (layerId) => {
  const { data } = await instance.post(`/layers/${layerId}/subscribe`);
  return data;
};

export const unsubscribeFromLayer = async (layerId) => {
  const { data } = await instance.post(`/layers/${layerId}/unsubscribe`);
  return data;
};
