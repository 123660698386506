import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSubscriptions, subscribeToLayer, unsubscribeFromLayer } from '../../services/api';
import { handleError } from '../../utils';

export const useGetSubscriptions = (query, options = {}) => {
  return useQuery(['subscriptions', query], getSubscriptions, {
    onSuccess: (data) => {
      console.log('Fetched subscriptions', data);
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'There was an error fetching the subscriptions' });
    },
    ...options,
  });
};

export const useSubscribeToLayer = () => {
  const queryClient = useQueryClient();

  return useMutation(subscribeToLayer, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['layer']);
      queryClient.invalidateQueries(['subscribedLayers']);
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'There was an error subscribing to this layer' });
    },
  });
};

export const useUnsubscribeFromLayer = () => {
  const queryClient = useQueryClient();

  return useMutation(unsubscribeFromLayer, {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(['layer']);
      queryClient.invalidateQueries(['subscribedLayers']);
    },
    onError: (error) => {
      handleError(error, { fallbackMessage: 'There was an error unsubscribing to this layer' });
    },
  });
};
