import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, ListGroup, Form } from 'react-bootstrap';
import QR from '../QR';
import { useGetLayers } from '../../hooks/data';
import { useAppStore } from '../../store';
import { useIsAdmin } from '../../hooks/user';

const AssetCodeModal = ({ data, activeCampaignCode, onHideModal }) => {
  const { user } = useAppStore();
  const isAdmin = useIsAdmin();

  const { data: campaignData = [] } = useGetLayers({
    owner: isAdmin ? undefined : user?._id,
    campaignCode: activeCampaignCode ? activeCampaignCode : { $exists: true },
    pins: data?._id,
  });

  const [campaignCode, setCampaignCode] = useState('play');

  const baseURL = process.env.REACT_APP_CLIP_URL;
  const appClipLink = `${baseURL}/${campaignCode}?a=${data?.shortCode.toLowerCase()}`; // &e=1

  // if there is a campaign code then set the select code to the campaign code
  // useEffect(() => {
  //   if (campaignData?.length > 0) {
  //     setCampaignCode(campaignData[0].campaignCode);
  //   }
  // }, [campaignData]);

  return (
    <Modal show={data} onHide={onHideModal} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>Share this Collectable</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This will generate a link and an Apple App Clip QR code that you can
          share with your friends.
        </p>
        <Form>
          <Form.Group className="mb-2" controlId="campaignCode">
            <Form.Label>Choose a theme</Form.Label>
            <Form.Control
              as="select"
              name="campaignCode"
              onChange={(e) => setCampaignCode(e.target.value)}
              value={campaignCode}
            >
              {campaignData?.map((campaign) => (
                <option key={campaign._id} value={campaign.campaignCode}>
                  {campaign.title}
                </option>
              ))}
              <option key={'pl-1'} value={'play'}>
                Playlayer Branded
              </option>
            </Form.Control>
          </Form.Group>
        </Form>

        <ListGroup className="mb-4">
          <ListGroup.Item
            onClick={() => window.open(appClipLink, '_blank')}
            className="cursor-pointer"
          >
            <b>{appClipLink}</b> - Tap to open
          </ListGroup.Item>
        </ListGroup>

        <div className="w-50 mx-auto">
          <QR target={appClipLink} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-dark"
          onClick={onHideModal}
          className="me-auto"
        >
          Close
        </Button>
        <Button variant="primary" onClick={onHideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssetCodeModal;
